import React from "react";
import { memo, RefObject, useEffect, useMemo, useState } from "react";
import { MRT_TableBodyRowGrabHandle } from "./MRT_TableBodyRowGrabHandle";
import { MRT_TableBodyCellValue } from "./MRT_TableBodyCellValue";
import {
  getCommonCellStyles,
  getIsFirstColumn,
  getIsLastColumn,
} from "../../column.utils";
import { MRT_Cell, MRT_RowData, MRT_TableInstance } from "../../types";
import { MRT_CopyButton } from "../buttons";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface Props<TData extends MRT_RowData> {
  cell: MRT_Cell<TData>;
  measureElement?: (element: HTMLTableCellElement) => void;
  numRows?: number;
  rowIndex: number;
  rowRef: RefObject<HTMLTableRowElement>;
  table: MRT_TableInstance<TData> | any;
  virtualIndex?: number;
}
export const MRT_TableBodyCell = <TData extends MRT_RowData>({
  cell,
  measureElement,
  numRows,
  rowIndex,
  rowRef,
  table,
}: Props<TData>) => {
  const {
    getState,
    options: {
      createDisplayMode,
      editDisplayMode,
      enableClickToCopy,
      enableColumnOrdering,
      enableEditing,
      enableGrouping,
      enableRowNumbers,
      layoutMode,
      muiSkeletonProps,
      muiTableBodyCellProps,
      rowNumberMode,
    },
    refs: { editInputRefs },
    setEditingCell,
    setHoveredColumn,
  } = table;
  const {
    creatingRow,
    draggingColumn,
    draggingRow,
    editingCell,
    editingRow,
    hoveredColumn,
    hoveredRow,
    density,
    isLoading,
    showSkeletons,
  } = getState();
  const { column, row } = cell;
  const { columnDef } = column;
  const { columnDefType } = columnDef;
  const mTableCellBodyProps =
    muiTableBodyCellProps instanceof Function
      ? muiTableBodyCellProps({ cell, column, row, table })
      : muiTableBodyCellProps;

  const mcTableCellBodyProps =
    columnDef.muiTableBodyCellProps instanceof Function
      ? columnDef.muiTableBodyCellProps({ cell, column, row, table })
      : columnDef.muiTableBodyCellProps;

  const tableCellProps = {
    ...mTableCellBodyProps,
    ...mcTableCellBodyProps,
  };

  const skeletonProps =
    muiSkeletonProps instanceof Function
      ? muiSkeletonProps({ cell, column, row, table })
      : muiSkeletonProps;

  const [skeletonWidth, setSkeletonWidth] = useState(100);
  useEffect(() => {
    if ((!isLoading && !showSkeletons) || skeletonWidth !== 100) return;
    const size = column.getSize();
    setSkeletonWidth(
      columnDefType === "display"
        ? size / 2
        : Math.round(Math.random() * (size - size / 3) + size / 3)
    );
  }, [isLoading, showSkeletons]);

  const draggingBorders = useMemo(() => {
    const isDraggingColumn = draggingColumn?.id === column.id;
    const isHoveredColumn = hoveredColumn?.id === column.id;
    const isDraggingRow = draggingRow?.id === row.id;
    const isHoveredRow = hoveredRow?.id === row.id;
    const isFirstColumn = getIsFirstColumn(column, table);
    const isLastColumn = getIsLastColumn(column, table);
    const isLastRow =
      typeof numRows === "number" ? rowIndex === numRows - 1 : -1;

    const borderStyle =
      isDraggingColumn || isDraggingRow
        ? `1px dashed darkgray !important`
        : isHoveredColumn || isHoveredRow
        ? `2px dashed deepskyblue !important`
        : undefined;

    return borderStyle
      ? {
          borderLeft:
            isDraggingColumn ||
            isHoveredColumn ||
            ((isDraggingRow || isHoveredRow) && isFirstColumn)
              ? borderStyle
              : undefined,
          borderRight:
            isDraggingColumn ||
            isHoveredColumn ||
            ((isDraggingRow || isHoveredRow) && isLastColumn)
              ? borderStyle
              : undefined,
          borderBottom:
            isDraggingRow || isHoveredRow || isLastRow
              ? borderStyle
              : undefined,
          borderTop: isDraggingRow || isHoveredRow ? borderStyle : undefined,
        }
      : undefined;
  }, [draggingColumn, draggingRow, hoveredColumn, hoveredRow, rowIndex]);

  const isEditable =
    (enableEditing instanceof Function ? enableEditing(row) : enableEditing) &&
    (columnDef.enableEditing instanceof Function
      ? columnDef.enableEditing(row)
      : columnDef.enableEditing) !== false;

  const isEditing =
    isEditable &&
    typeof editDisplayMode !== "undefined" &&
    !["modal", "custom"].includes(editDisplayMode) &&
    (editDisplayMode === "table" ||
      editingRow?.id === row.id ||
      editingCell?.id === cell.id) &&
    !row.getIsGrouped();

  const isCreating =
    isEditable && createDisplayMode === "row" && creatingRow?.id === row.id;

  const handleDoubleClick = (event: React.SyntheticEvent<EventTarget>) => {
    tableCellProps?.onDoubleClick?.(event);
    if (isEditable && editDisplayMode === "cell") {
      setEditingCell(cell);
      queueMicrotask(() => {
        const textField = editInputRefs.current[column.id];
        if (textField) {
          textField.focus();
          textField.select?.();
        }
      });
    }
  };

  const handleDragEnter = (e: React.SyntheticEvent<EventTarget>) => {
    tableCellProps?.onDragEnter?.(e);
    if (enableGrouping && hoveredColumn?.id === "drop-zone") {
      setHoveredColumn(null);
    }
    if (enableColumnOrdering && draggingColumn) {
      setHoveredColumn(
        columnDef.enableColumnOrdering !== false ? column : null
      );
    }
  };
  return (
    <td
      className={classNames(
        "headless_tbody-td",
        cell.column.columnDef.isSticky ? 'headless_rightSticky' : '',
        layoutMode === "grid" ? "headless_items-center" : "",
        density === "compact"
          ? columnDefType === "display"
            ? "headless_p-0_05"
            : "headless_p-05"
          : density === "comfortable"
          ? columnDefType === "display"
            ? "headless_p-05_075"
            : "headless_p-05 headless_pt-3 headless_pb-08"
          : columnDefType === "display"
          ? "headless_p-1_125"
          : "headless_p-15",

        column.id === "mrt-row-expand"
          ? `pl-[${
              row.depth +
              (density === "compact"
                ? 0.5
                : density === "comfortable"
                ? 0.75
                : 1.25)
            }rem]`
          : (column.id === "compare") ? "compare" : "",
        columnDefType !== "display" ? "headless_text-ellipsis" : "",
        layoutMode === "grid" ? `justify-[${tableCellProps.align}]` : "",
        ["table", "cell"].includes(editDisplayMode ?? "")
          ? "headless_hover-outline-1"
          : "",
        ["table", "cell"].includes(editDisplayMode ?? "")
          ? "headless_hover-outline"
          : "",
        ["table", "cell"].includes(editDisplayMode ?? "")
          ? `headless_hover-outline-default-color`
          : "",

        draggingColumn?.id === column.id
          ? "headless_z-2"
          : column.getIsPinned()
          ? "headless_z-1"
          : "headless_z-0",
        isEditable && editDisplayMode === "cell"
          ? "headless_cursor-pointer"
          : "headless_cursor-inherit",
          !column.columnDef.header ? "isNull": ''
      )}
      style={{
        ...draggingBorders,
        ...getCommonCellStyles({
          column,
          table,
          // tableCellProps,
        }),
      }}
      ref={(node) => {
        if (node) {
          measureElement?.(node);
        }
      }}
      {...tableCellProps}
      onDragEnter={handleDragEnter}
      onDoubleClick={handleDoubleClick}
    >
      <>
        {cell.getIsPlaceholder() ? (
          columnDef.PlaceholderCell?.({ cell, column, row, table }) ?? null
        ) : (isLoading || showSkeletons) && cell.getValue() === null ? (
          <div
            {...skeletonProps}
            className={classNames(
              `w-[${skeletonWidth}] animate-pulse h-5 flex`
            )}
          />
        ) : enableRowNumbers &&
          rowNumberMode === "static" &&
          column.id === "mrt-row-numbers" ? (
          rowIndex + 1
        ) : column.id === "mrt-row-drag" ? (
          <MRT_TableBodyRowGrabHandle row={row} rowRef={rowRef} table={table} />
        ) : columnDefType === "display" &&
          (column.id === "mrt-row-select" ||
            column.id === "mrt-row-expand" ||
            !row.getIsGrouped()) ? (
          <div className={"headless_cell_group_icon"}>
            {columnDef.Cell?.({
              cell,
              // @ts-ignore
              renderedCellValue: cell.renderValue(),
              column,
              row,
              table,
            })}
          </div>
        ) : (enableClickToCopy || columnDef.enableClickToCopy) &&
          columnDef.enableClickToCopy !== false ? (
          <MRT_CopyButton cell={cell} table={table}>
            <MRT_TableBodyCellValue cell={cell} table={table} />
          </MRT_CopyButton>
        ) : (
          <MRT_TableBodyCellValue cell={cell} table={table} />
        )}
        {/*{cell.getIsGrouped() && !columnDef.GroupedCell && (
          <> ({row.subRows?.length})</>
        )}*/}
      </>
    </td>
  );
};

export const Memo_MRT_TableBodyCell = memo(
  MRT_TableBodyCell,
  (prev, next) => next.cell === prev.cell
);
