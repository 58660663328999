// reducers/counter.ts

import HeaderConstant from "../constant/HeaderConstant";
import {HeaderActions, InitialHeaderState} from "../types";

const initialHeaderState: InitialHeaderState = {
    previousPath:'',
    tabData: [
        {
            label: 'SELECT DIAMOND',
            isActive: true,
            isDisabled: false,
            isIcon: false,
            openTab:'diamond',
            backButton:true
        },
        {
            label: 'SELECT SETTING',
            isActive: false,
            isDisabled: false,
            isIcon:false,
            openTab:'diamond',
            backButton:false
        }
    ]
}

const HeaderReducer = (state: InitialHeaderState = initialHeaderState, action: HeaderActions) => {
    switch (action.type) {
        case HeaderConstant.SET_HEADER_TAB_TO_DEFAULT:
            return {...initialHeaderState};
        case HeaderConstant.SET_HEADER_TAB_TO_SETTING_OWN:
            return {
                ...state,
                tabData: [
                    {
                        label: 'SELECT SETTING',
                        isActive: true,
                        isDisabled: false,
                        isIcon:false,
                        openTab:action.payload,
                        backButton:false
                    },
                    {
                        label: 'SELECT DIAMOND',
                        isActive: false,
                        isDisabled: false,
                        isIcon: false,
                        backButton:true,
                        openTab:action.payload,
                    }
                ]
            };
        case HeaderConstant.SET_HEADER_TAB_DIAMOND_ICON:
            return {
                ...state,
                tabData: [
                    {
                        label: 'SELECT DIAMOND',
                        isActive: false,
                        isDisabled: false,
                        isIcon: true,
                        backButton:true,
                        openTab:action.payload,
                    },
                    {
                        label: 'SELECT SETTING',
                        isActive: true,
                        isDisabled: false,
                        isIcon: false,
                        openTab:action.payload,
                        backButton:false,

                    }
                ]
            };
        case HeaderConstant.SET_HEADER_TAB_SETTING_ICON:
            return {
                ...state,
                tabData: [
                    {
                        label: 'SELECT SETTING',
                        isActive: false,
                        isDisabled: false,
                        isIcon: true,
                        openTab:'diamond',
                        backButton:true
                    },
                    {
                        label: 'SELECT DIAMOND',
                        isActive: true,
                        isDisabled: false,
                        isIcon: false,
                        openTab:'diamond',
                        backButton:true
                    }
                ]
            };
        case HeaderConstant.SET_BOTH_TAB_ICONE_TRUE:
            return {
                ...state,
                tabData: [
                    {
                        label: action.payload[0],
                        isActive: false,
                        isDisabled: true,
                        isIcon: true,
                        backButton:true
                    },
                    {
                        label: action.payload[1],
                        isActive: false,
                        isDisabled: true,
                        isIcon: true,
                        backButton:true
                    }
                ]
            }
        case HeaderConstant.SET_PREVIOUS_URL_PATH:
            return {
                ...initialHeaderState,
                previousPath:action.payload
            }
        case HeaderConstant.SET_INITIAL_HEADER:
            return initialHeaderState;
        default:
            return state;
    }
}

export default HeaderReducer;