import { useNavigate } from "react-router-dom";
import {useCallback, useEffect, useRef, useState} from "react";
import {TableData, PropsFromScreenManager} from "../../types";
import {HKCustomize, HKRing} from "../Images";
import { useParams } from 'react-router-dom';
import {propertyMappings, source} from "../Diamond/useDiamond";
import {getDiamondCode} from "../../Utils/getDiamondCode";
import { buildYourOwnValue, findNearestValue } from '../../Utils/findNearestCaratValue';
import {PropsFromDiamondDetailsRedux} from "./DiamondDetailsPage";
import { getCenterDiamondSize } from '../../Utils/getExclusionDiamondSize';
import { exclusionMatrixData, getExclusionOptions } from '../../Utils/getExclusionOptions';
import { initialExclusionKey } from '../../Utils/initialExclusionKey';

const useDiamondDetailsPage = (props:PropsFromDiamondDetailsRedux & PropsFromScreenManager) => {
  const {
    ring,
    diamond,
    footer,
    setDiamondDetailsSuccess,
    setIsShowFooter,
    instanceData: { screenManager },
    setDiamondDetailsLoading,
    setInitialOption,
    setBackDiamondDetails,
    setDiamondDetailsFail,
    setInitialDiamondOption,
    setFooterToggle,
    setJourneyStartedFrom,
    setSelectedDiamondLoading,
    setSelectedDiamondData,
    setSelectedDiamondFail,
    setBackToSelectDiamondPage,
    setExclusionOption,
    setIsCartLoading,
    setDiamondTabIconTrue
  } = props;
  const {id}  = useParams();
  const navigate = useNavigate();
  const hasEffectRun = useRef(false);
  const DetailsData = useCallback(async () => {
    setDiamondDetailsLoading();
    try {
      const currentUrl = window.location.href;
      const match = currentUrl.match(/diamondId=([^&]*)/);
      let diamondId =match?.[1] || '';
      /*if (match) {
        diamondId = match[1];
      } else {
        console.log('diamondId not found in the URL.');
      }*/

      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/?id='${diamondId}'`,
      );
      if (!response.ok) {
        setDiamondDetailsFail(
          new Error(`HTTP error! Status: ${response.status}`),
        );
      }
      const result: TableData = await response.json();

      Object.keys(propertyMappings).forEach((property: string) => {
        result[property] = propertyMappings[property][result[property] as number  - 1];
      });
      const updatedResult = {
        ...result,
        shape:result.shape,
        carat:result.carat,
        source: result.source === 'GNU' ? source[0] : source[1],
        // diamond_price : result.diamond_price !== null ? `${result.diamond_price}` : null
      }
      setDiamondDetailsSuccess(updatedResult);
      if(ring.isBackToDiamondSelection){
        setInitialOption();
        setBackDiamondDetails(false);
      }
    } catch (e) {
      setDiamondDetailsFail(e);
    }
  },[id,setDiamondDetailsSuccess, setDiamondDetailsLoading, setDiamondDetailsFail,fetch]);
    useEffect(()=>{
      if(!hasEffectRun.current){
        setInitialDiamondOption();
        DetailsData();
      }
      (screenManager as any).props.setStateManagerData(
          'currentScreen',
          'Diamond details screen',
      );
      (screenManager as any).props.setStateManagerData(
          'currentView',
          'Diamond details view',
      );
      return () => {
        hasEffectRun.current = true;
      }
    },[])
    const handleContinueWithSetting = () => {
      setJourneyStartedFrom('details');
      setIsShowFooter(true);
      setDiamondTabIconTrue('setting');
      // navigate(`/start-with-setting?diamondId=${id}`);
      const currentUrl = window.location.href;
      const match = currentUrl.match(/diamondId=([^&]*)/);
      let diamondId =match?.[1] || '';
      (screenManager as any).changeScreen({ viewName: 'setting', id: diamondId });
    };
    const handleDesignYourOwnEngagement = () => {
      setJourneyStartedFrom('details');
      setIsShowFooter(true);
      setDiamondTabIconTrue('dyo');
      // navigate(`/ring-builder?styleId=RB001&diamondId=${id}`);
      const currentUrl = window.location.href;
      const match_diamondId = currentUrl.match(/diamondId=([^&]*)/);
      let diamondId=match_diamondId?.[1] || '';
      const match_styleId = currentUrl.match(/styleId=([^&]*)/);
      let styleId=match_styleId?.[1] || '';
      
      (screenManager as any).changeScreen({ viewName: 'dyo',styleId:styleId||'RB001', id: diamondId });
    };
  const dialogDetails = [
    {
      title: `Design Your Own Setting`,
      img: HKRing,
      paragraph: `Select a ring setting from our exclusive curation.`,
      handleClick: handleDesignYourOwnEngagement,
    },
    {
      title: `Start With an Existing Setting`,
      img: HKCustomize,
      paragraph: `Design your own engagement ring by customizing your ring’s style, setting, metal and so much more.`,
      handleClick: handleContinueWithSetting,
    },
  ];

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const closeDialogBox = useCallback(() => {
    setIsOpenDialog(false);
  },[setIsOpenDialog])

  const openDialogBox = useCallback(() => {
    setIsOpenDialog(true);
  },[setIsOpenDialog])

  const handleSelectThisDiamond = async () => {
    if(Object.keys(ring.options).length > 0){
      setFooterToggle();
      setIsCartLoading(true);
      const exclusionMatrix = await exclusionMatrixData(footer.styleID);
      const {shape,carat,source} = diamond.details;
      let isExclusionDiamond = false ;
      if(shape && carat && source){
        isExclusionDiamond = ring.exclusionOption.includes(findNearestValue(+carat));
        const result = await getDiamondCode({"shape":shape as string,"type":source as string},setSelectedDiamondLoading,setSelectedDiamondFail);
        if(result) {
          if(result["Diamond_Shape"] !== ring.options["Diamond_Shape"]){
            const ringExclusionMatrix = getExclusionOptions(exclusionMatrix ,"Diamond_Shape",result["Diamond_Shape"],initialExclusionKey(footer.styleID.includes('RP')),ring.options);
            isExclusionDiamond = [...Object.values(ringExclusionMatrix).flat()]?.includes(findNearestValue(+carat));
            setExclusionOption([...Object.values(ringExclusionMatrix).flat()]);
            setSelectedDiamondData({...result,Center_Diamond_Size: isExclusionDiamond ? getCenterDiamondSize([...Object.values(ringExclusionMatrix).flat()]) : findNearestValue(+carat)});
          } else {
            setSelectedDiamondData({...result,Center_Diamond_Size: isExclusionDiamond ? getCenterDiamondSize(ring.exclusionOption) : findNearestValue(+carat)});
          }
        }
      }
      return setIsCartLoading(false)
    }
    (screenManager as any).props.setStateManagerData(
      'selectedDiamond',
      diamond.details,
    );

    openDialogBox();
  }
  const handleBackDiamondSearch = useCallback(() => {
    // recentDataSuccess(diamond.details);
    setBackToSelectDiamondPage(true);
    if(!footer.isRedirectToEdit){
       setDiamondDetailsSuccess({});
    }
    // navigate(-1);
    if(Object.keys(ring.options).length > 0){
      setIsShowFooter(true);
    }else {
      setIsShowFooter(false);
    }
    (screenManager as any).changeScreen({ viewName: 'diamond', id: null });
    (screenManager as any).props.setStateManagerData(
        'selectedDiamond',
        '',
    );
  },[screenManager,ring,setIsShowFooter,navigate,setDiamondDetailsSuccess])
  return {
    navigate,
    handleSelectThisDiamond,
    isOpenDialog,
    closeDialogBox,
    openDialogBox,
    dialogDetails,
    handleBackDiamondSearch
  };
};
export default useDiamondDetailsPage;
