import React, {FC, useState} from "react";
import classes from './floatingLabel.module.css';
import useFloatingLabel from "./useFloatingLabel";
import {setRingOptionsType} from "../../types";

export interface FloatingLabelType {
    label : string;
    maxLength: number;
    labelId: string;
    keyName: string;
    value: string;
    setRingOptions:setRingOptionsType
}

const FloatingLabel:FC<FloatingLabelType> = ({ label,labelId, maxLength ,setRingOptions,keyName,value, ...props }) => {
    const {inputValue ,handleChange} = useFloatingLabel({maxLength,setRingOptions,keyName,value});
    return (
        <div className={classes.root}>
            <input type="text"
                   id={labelId}
                   placeholder={label}
                   className={`${classes.input}`}
                   onChange={handleChange}
                   value={value ? value : ''}
                   maxLength = {maxLength}
            />
            <span className={classes.counter}>{inputValue.length} / {maxLength}</span>
            <label htmlFor={labelId}
                   className={classes.label}
            >
                {label}
            </label>
        </div>
    )
}
export default FloatingLabel;
