export default {
  THREE_D_IMAGE_LOADING : 'THREE_D_IMAGE_LOADING',
  THREE_D_IMAGE_SUCCESS: 'THREE_D_IMAGE_SUCCESS',
  SET_INITIAL_CART: 'SET_INITIAL_CART',
  CART_IMAGE_SUCCESS: 'CART_IMAGE_SUCCESS',
  CART_THUMB_IMAGE_SUCCESS: 'CART_THUMB_IMAGE_SUCCESS',
  SET_PRODUCT_DETAIL:'SET_PRODUCT_DETAIL',
  SET_IS_CART_LOADING:'SET_IS_CART_LOADING'

}
