import CartConstant from "../constant/CartConstant";

export const set3DImageLoading = () => ({
  type: CartConstant.THREE_D_IMAGE_LOADING
});

export const setIsCartLoading = (value:boolean) => ({
  type: CartConstant.SET_IS_CART_LOADING,
  payload: value
});
export const set3DImageSuccess = (value:string) => ({
  type: CartConstant.THREE_D_IMAGE_SUCCESS,
  payload: value
});

export const setInitialCart = () => ({
  type: CartConstant.SET_INITIAL_CART,
});

export const setCartImageSuccess = (value:string[]) => ({
  type: CartConstant.CART_IMAGE_SUCCESS,
  payload: value
});

export const setCartThumbImageSuccess = (value:string[]) => ({
  type: CartConstant.CART_THUMB_IMAGE_SUCCESS,
  payload: value
});

export const setProductDetail = (value:{[key:string]:string|string[]|boolean|number})=>({
  type :CartConstant.SET_PRODUCT_DETAIL,
  payload:value
})
