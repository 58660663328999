import React, { ReactNode, useRef, useState } from "react";
import {
  ColumnDef,
  createRow as _createRow,
  flexRender as _flexRender,
  Renderable,
  Row,
} from "@tanstack/react-table";
import { MRT_AggregationFns } from "./aggregationFns";
import { MRT_FilterFns } from "./filterFns";
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_ColumnHelper,
  MRT_ColumnOrderState,
  MRT_DefinedColumnDef,
  MRT_DisplayColumnDef,
  MRT_DisplayColumnIds,
  MRT_FilterOption,
  MRT_GroupColumnDef,
  MRT_GroupingState,
  MRT_Header,
  MRT_Row,
  MRT_RowData,
  MRT_TableInstance,
  MRT_TableOptions,
  TableMeta,
} from "./types";
import { MRT_SortingFns } from "./sortingFns";
import { Person } from "../../../../types";

export const getColumnId = <TData extends MRT_RowData>(
  columnDef: MRT_ColumnDef<TData>
): string =>
  columnDef.id ?? columnDef.accessorKey?.toString?.() ?? columnDef.header;

export const getAllLeafColumnDefs = <TData extends MRT_RowData>(
  columns: MRT_ColumnDef<TData>[]
): MRT_ColumnDef<TData>[] => {
  const allLeafColumnDefs: MRT_ColumnDef<TData>[] = [];
  const getLeafColumns = (cols: MRT_ColumnDef<TData>[]) => {
    cols.forEach((col) => {
      if (col.columns) {
        getLeafColumns(col.columns);
      } else {
        allLeafColumnDefs.push(col);
      }
    });
  };
  getLeafColumns(columns);
  return allLeafColumnDefs;
};

export const prepareColumns = <TData extends MRT_RowData>({
  aggregationFns,
  columnDefs,
  columnFilterFns,
  defaultDisplayColumn,
  filterFns,
  sortingFns,
}: {
  aggregationFns: typeof MRT_AggregationFns &
    MRT_TableOptions<TData>["aggregationFns"];
  columnDefs: MRT_ColumnDef<TData>[];
  columnFilterFns: { [key: string]: MRT_FilterOption };
  defaultDisplayColumn: Partial<MRT_ColumnDef<TData>>;
  filterFns: typeof MRT_FilterFns & MRT_TableOptions<TData>["filterFns"];
  sortingFns: typeof MRT_SortingFns & MRT_TableOptions<TData>["sortingFns"];
}): MRT_DefinedColumnDef<TData>[] =>
  columnDefs.map((columnDef) => {
    //assign columnId
    if (!columnDef.id) columnDef.id = getColumnId(columnDef);
    if (process.env.NODE_ENV !== "production" && !columnDef.id) {
      console.error(
        "Column definitions must have a valid `accessorKey` or `id` property"
      );
    }

    //assign columnDefType
    if (!columnDef.columnDefType) columnDef.columnDefType = "data";
    if (columnDef.columns?.length) {
      columnDef.columnDefType = "group";
      //recursively prepare columns if this is a group column
      columnDef.columns = prepareColumns({
        aggregationFns,
        columnDefs: columnDef.columns,
        columnFilterFns,
        defaultDisplayColumn,
        filterFns,
        sortingFns,
      });
    } else if (columnDef.columnDefType === "data") {
      //assign aggregationFns if multiple aggregationFns are provided
      if (Array.isArray(columnDef.aggregationFn)) {
        const aggFns = columnDef.aggregationFn as string[];
        columnDef.aggregationFn = (
          columnId: string,
          leafRows: Row<TData>[],
          childRows: Row<TData>[]
        ) =>
          aggFns.map((fn) =>
            aggregationFns[fn]?.(columnId, leafRows, childRows)
          );
      }

      //assign filterFns
      if (Object.keys(filterFns).includes(columnFilterFns[columnDef.id])) {
        columnDef.filterFn =
          filterFns[columnFilterFns[columnDef.id]] ?? filterFns.fuzzy;
        (columnDef as MRT_DefinedColumnDef<TData>)._filterFn =
          columnFilterFns[columnDef.id];
      }

      //assign sortingFns
      if (Object.keys(sortingFns).includes(columnDef.sortingFn as string)) {
        // @ts-ignore
        columnDef.sortingFn = sortingFns[columnDef.sortingFn];
      }
    } else if (columnDef.columnDefType === "display") {
      columnDef = {
        ...(defaultDisplayColumn as MRT_ColumnDef<TData>),
        ...columnDef,
      };
    }
    return columnDef;
  }) as MRT_DefinedColumnDef<TData>[];

export const reorderColumn = <TData extends MRT_RowData>(
  draggedColumn: MRT_Column<TData>,
  targetColumn: MRT_Column<TData>,
  columnOrder: MRT_ColumnOrderState
): MRT_ColumnOrderState => {
  if (draggedColumn.getCanPin()) {
    draggedColumn.pin(targetColumn.getIsPinned());
  }
  const newColumnOrder = [...columnOrder];
  newColumnOrder.splice(
    newColumnOrder.indexOf(targetColumn.id),
    0,
    newColumnOrder.splice(newColumnOrder.indexOf(draggedColumn.id), 1)[0]
  );
  return newColumnOrder;
};

export const showExpandColumn = <TData extends MRT_RowData>(
  props: MRT_TableOptions<TData>,
  grouping?: MRT_GroupingState
) =>
  !!(
    props.enableExpanding ||
    (props.enableGrouping && (grouping === undefined || grouping?.length)) ||
    props.renderDetailPanel
  );

export const getLeadingDisplayColumnIds = <TData extends MRT_RowData>(
  props: MRT_TableOptions<TData>
) =>
  [
    props.enableRowPinning &&
      !props.rowPinningDisplayMode?.startsWith("select") &&
      "mrt-row-pin",
    (props.enableRowDragging || props.enableRowOrdering) && "mrt-row-drag",
    props.positionActionsColumn === "first" &&
      (props.enableRowActions ||
        (props.enableEditing &&
          ["modal", "row"].includes(props.editDisplayMode ?? ""))) &&
      "mrt-row-actions",
    props.positionExpandColumn === "first" &&
      showExpandColumn(props) &&
      "mrt-row-expand",
    props.enableRowSelection && "mrt-row-select",
    props.enableRowNumbers && "mrt-row-numbers",
  ].filter(Boolean) as MRT_DisplayColumnIds[];

export const getTrailingDisplayColumnIds = <TData extends MRT_RowData>(
  props: MRT_TableOptions<TData>
) =>
  [
    props.positionActionsColumn === "last" &&
      (props.enableRowActions ||
        (props.enableEditing &&
          ["modal", "row"].includes(props.editDisplayMode ?? ""))) &&
      "mrt-row-actions",
    props.positionExpandColumn === "last" &&
      showExpandColumn(props) &&
      "mrt-row-expand",
    props.layoutMode === "grid-no-grow" && "mrt-row-spacer",
  ].filter(Boolean) as MRT_DisplayColumnIds[];

export const getDefaultColumnOrderIds = <TData extends MRT_RowData>(
  props: MRT_TableOptions<TData>
) => {
  const leadingDisplayCols: string[] = getLeadingDisplayColumnIds(props);
  const trailingDisplayCols: string[] = getTrailingDisplayColumnIds(props);
  const allLeafColumnDefs = getAllLeafColumnDefs(props.columns)
    .map((columnDef) => getColumnId(columnDef))
    .filter(
      (columnId) =>
        !leadingDisplayCols.includes(columnId) &&
        !trailingDisplayCols.includes(columnId)
    );
  return [ ...allLeafColumnDefs,...leadingDisplayCols, ...trailingDisplayCols];
};

export const getDefaultColumnFilterFn = <TData extends MRT_RowData>(
  columnDef: MRT_ColumnDef<TData>
): MRT_FilterOption => {
  if (columnDef.filterVariant === "multi-select") return "arrIncludesSome";
  if (columnDef.filterVariant?.includes("range")) return "betweenInclusive";
  if (
    columnDef.filterVariant === "select" ||
    columnDef.filterVariant === "checkbox"
  )
    return "equals";
  return "fuzzy";
};

export const getIsFirstColumn = <TData extends MRT_RowData>(
  column: MRT_Column<TData>,
  table: MRT_TableInstance<TData>
) => {
  const leftColumns = table.getLeftVisibleLeafColumns();
  return leftColumns.length
    ? leftColumns[0].id === column.id
    : table.getVisibleLeafColumns()[0].id === column.id;
};

export const getIsLastColumn = <TData extends MRT_RowData>(
  column: MRT_Column<TData>,
  table: MRT_TableInstance<TData>
) => {
  const rightColumns = table.getRightVisibleLeafColumns();
  const columns = table.getVisibleLeafColumns();
  return rightColumns.length
    ? rightColumns[rightColumns.length - 1].id === column.id
    : columns[columns.length - 1].id === column.id;
};

export const getIsLastLeftPinnedColumn = <TData extends MRT_RowData>(
  table: MRT_TableInstance<TData>,
  column: MRT_Column<TData>
) => {
  return (
    column.getIsPinned() === "left" &&
    table.getLeftLeafHeaders().length - 1 === column.getPinnedIndex()
  );
};

export const getIsFirstRightPinnedColumn = <TData extends MRT_RowData>(
  column: MRT_Column<TData>
) => {
  return column.getIsPinned() === "right" && column.getPinnedIndex() === 0;
};

export const getTotalRight = <TData extends MRT_RowData>(
  table: MRT_TableInstance<TData>,
  column: MRT_Column<TData>
) => {
  return table
    .getRightLeafHeaders()
    .slice(column.getPinnedIndex() + 1)
    .reduce((acc, col) => acc + col.getSize(), 0);
};

export const getCanRankRows = <TData extends MRT_RowData>(
  table: MRT_TableInstance<TData>
) => {
  const { getState, options } = table;
  const {
    enableGlobalFilterRankedResults,
    manualExpanding,
    manualFiltering,
    manualGrouping,
    manualSorting,
  } = options;
  const { expanded, globalFilterFn } = getState();

  return (
    !manualExpanding &&
    !manualFiltering &&
    !manualGrouping &&
    !manualSorting &&
    enableGlobalFilterRankedResults &&
    globalFilterFn === "fuzzy" &&
    expanded !== true &&
    !Object.values(expanded).some(Boolean)
  );
};

export const parseFromValuesOrFunc = <T, U>(
  fn: ((arg: U) => T) | T | undefined,
  arg: U
): T | undefined => (fn instanceof Function ? fn(arg) : fn);

export const flexRender = _flexRender as (
  Comp: Renderable<any>,
  props: any
) => JSX.Element | ReactNode;

export const createRow = <TData extends MRT_RowData>(
  table: MRT_TableInstance<TData>,
  originalRow?: TData
): MRT_Row<TData> =>
  _createRow(
    table as any,
    "mrt-row-create",
    originalRow ??
      Object.assign(
        {},
        ...getAllLeafColumnDefs(table.options.columns).map((col) => ({
          [getColumnId(col)]: "",
        }))
      ),
    -1,
    0
  ) as MRT_Row<TData>;

export function createMRTColumnHelper<
  TData extends MRT_RowData
>(): MRT_ColumnHelper<TData> {
  return {
    accessor: (accessor, column) => {
      return typeof accessor === "function"
        ? ({
            ...column,
            accessorFn: accessor,
          } as any)
        : {
            ...column,
            accessorKey: accessor,
          };
    },
    display: (column) => column as MRT_DisplayColumnDef<TData>,
    group: (column) => column as MRT_GroupColumnDef<TData>,
  };
}
export const getCommonCellStyles = <TData extends MRT_RowData>({
  column,
  header,
  table,
}: {
  column: MRT_Column<TData>;
  header?: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}) => {
  const widthStyles = {
    minWidth: `max(calc(var(--${header ? "header" : "col"}-${
      header?.id ?? column.id
    }-size) * 1px), ${column.columnDef.minSize ?? 30}px)`,
    width: `calc(var(--${header ? "header" : "col"}-${
      header?.id ?? column.id
    }-size) * 1px)`,
  };
  return {
    backgroundColor:
      column.getIsPinned() && column.columnDef.columnDefType !== "group"
        ? "rgba(0,0,0,0.04)"
        : "inherit",
    backgroundImage: "inherit",
    boxShadow: getIsLastLeftPinnedColumn(table, column)
      ? `-4px 0 8px -6px rgba(0,0,0,0.2)} inset`
      : getIsFirstRightPinnedColumn(column)
      ? `4px 0 8px -6px rgba(0,0,0,0.2)} inset`
      : undefined,
    display: table.options.layoutMode === "grid" ? "flex" : "table-cell",
    flex:
      table.options.layoutMode === "grid"
        ? `var(--${header ? "header" : "col"}-${
            header?.id ?? column.id
          }-size) 0 auto`
        : undefined,
    left:
      column.getIsPinned() === "left"
        ? `${column.getStart("left")}px`
        : undefined,
    ml:
      table.options.enableColumnVirtualization &&
      column.getIsPinned() === "left" &&
      column.getPinnedIndex() === 0
        ? `-${
            column.getSize() *
            (table.getState().columnPinning.left?.length ?? 1)
          }px`
        : undefined,
    mr:
      table.options.enableColumnVirtualization &&
      column.getIsPinned() === "right" &&
      column.getPinnedIndex() === table.getVisibleLeafColumns().length - 1
        ? `-${
            column.getSize() *
            (table.getState().columnPinning.right?.length ?? 1) *
            1.2
          }px`
        : undefined,
    opacity:
      table.getState().draggingColumn?.id === column.id ||
      table.getState().hoveredColumn?.id === column.id
        ? 0.5
        : 1,
    position:
      column.getIsPinned() && column.columnDef.columnDefType !== "group"
        ? "sticky"
        : undefined,
    right:
      column.getIsPinned() === "right"
        ? `${getTotalRight(table, column)}px`
        : undefined,
    transition: table.options.enableColumnVirtualization
      ? "none"
      : `padding 150ms ease-in-out`,
    ...(!table.options.enableColumnResizing && widthStyles), //let devs pass in width styles if column resizing is disabled
    ...(table.options.enableColumnResizing && widthStyles), //don't let devs pass in width styles if column resizing is enabled
  };
};
