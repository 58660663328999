export default {
  START_WITH_SETTING_FILTER_DATA_SUCCESS: 'START_WITH_SETTING_FILTER_DATA_SUCCESS',
  START_WITH_SETTING_FILTER_LOADING: 'START_WITH_SETTING_FILTER_LOADING',
  START_WITH_SETTING_FILTER_FAIL: 'START_WITH_SETTING_FILTER_FAIL',
  SET_START_WITH_SETTING_FILTER: 'SET_START_WITH_SETTING_FILTER',
  SET_RESET_START_WITH_SETTING_FILTER: 'SET_RESET_START_WITH_SETTING_FILTER',
  START_WITH_SETTING_PRODUCT_LOADING: 'START_WITH_SETTING_PRODUCT_LOADING',
  START_WITH_SETTING_PRODUCT_SUCCESS: 'START_WITH_SETTING_PRODUCT_SUCCESS',
  START_WITH_SETTING_PRODUCT_FAIL: 'START_WITH_SETTING_PRODUCT_FAIL',
  START_WITH_SETTING_MIN_MAX_VALUE: 'START_WITH_SETTING_MIN_MAX_VALUE',
  REMOVE_FILTER_KEY: 'REMOVE_FILTER_KEY',
  SET_START_WITH_SETTING_MIN_MAX_VALUE_CHANGE: 'SET_START_WITH_SETTING_MIN_MAX_VALUE_CHANGE',
  SET_INITIAL_START_WITH_SETTING: 'SET_INITIAL_START_WITH_SETTING',
  SET_AFTER_DIAMOND_SELECTED_OPTIONS: 'SET_AFTER_DIAMOND_SELECTED_OPTIONS',
  SET_SWATCH_COLOR: 'SET_SWATCH_COLOR',
  SET_OPTIONS_BY_PRODUCT:'SET_OPTIONS_BY_PRODUCT'
}
