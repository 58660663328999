import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {A11y, FreeMode, Navigation, Pagination, Thumbs} from 'swiper/modules';
import { MainImageSliderProps } from '../../types';
import { Swiper } from 'swiper/types';

interface State {
  thumbsHeight: number;
  allThumbnailCoordinates: { [key: string]: number };
  imageError: boolean[];
  handView: boolean;
  colorState: number;
  handViewImage: boolean;
  activeIndex: number;
}

const useMainImageSlider = (props: MainImageSliderProps) => {
  const { dots = true, setIsHandLoaded } = props;
  const islargeTab = useMediaQuery({ query: '(max-width: 1279px)' });

  const thumbsContainerRef = useRef<any>(null);
  const allThumbnailRef = useRef<any>(null);
  const initialState: State = {
    thumbsHeight: 65,
    allThumbnailCoordinates: {},
    imageError: [],
    handView: false,
    colorState: 0,
    handViewImage: false,
    activeIndex: 0
  };
  const [thumbsSwiper, setThumbsSwiper] = useState<Swiper | null>(null);
  const [swipeSlider, setSwipeSlider] = useState<State>(initialState);
  const [handleRange , setHandleRange] = useState(false);
  const getBackgroundColor: string = useMemo(() => {
    const startColor = [228, 194, 161]; // RGBA values for the start color
    const endColor = [82, 59, 44]; // RGBA values for the end color
    const color = startColor.map((channel, index) => {
      return Math.round(channel + (endColor[index] - channel) * (swipeSlider.colorState / 100));
    });

    return `rgba(${color.join(',')}, 1)`;
  }, [swipeSlider.colorState]);
  const handleImageError = useCallback((index: number) => {
    setSwipeSlider((prev) => {
      const newErrors = [...prev.imageError];
      newErrors[index] = true;
      return { ...prev, imageError: newErrors };
    });
  }, [setSwipeSlider]);

  const onColorSliderChange = useCallback((value: number | number[]) => {
      window.postMessage({ action: 'ToggleDarkHandSkin', value });
    setSwipeSlider((prev) => {
      return {
        ...prev, colorState: value as number
      };
    });
    setHandleRange(false);
  }, [setSwipeSlider]);

  const handleThumbsResize = useCallback(() => {
    if (thumbsContainerRef.current) {
      setSwipeSlider((prev) => {
        return {
          ...prev, thumbsHeight: (thumbsContainerRef.current as unknown as HTMLDivElement)?.clientHeight ?? 0
        };
      });
    }
  }, [setSwipeSlider, thumbsContainerRef.current]);

  const AllThumbnailCoordinates = useCallback(() => {
    const thumbnailRef = allThumbnailRef.current as HTMLDivElement | null;
    const swiperWrapper = thumbnailRef?.querySelector('.swiper-wrapper') as HTMLElement | null;
    if (swiperWrapper) {
      setSwipeSlider((prevState) => {
        return {
          ...prevState,
          allThumbnailCoordinates: swiperWrapper.getBoundingClientRect() as unknown as { [key: string]: number }
        };
      });
    }
  }, [allThumbnailRef, setSwipeSlider]);

  useLayoutEffect(() => {
    const intervalId = setTimeout(() => {
      AllThumbnailCoordinates();
    }, 100);

    window.addEventListener('resize', AllThumbnailCoordinates);

    return () => {
      clearTimeout(intervalId);
      window.removeEventListener('resize', AllThumbnailCoordinates);
    };
  }, [allThumbnailRef.current, swipeSlider.thumbsHeight]);

  const onSwiperInit = useCallback((swiper: Swiper) => {
    setThumbsSwiper(swiper);
    handleThumbsResize(); // This is triggered after Swiper is initialized
  }, [handleThumbsResize, setSwipeSlider]);

  useEffect(() => {
    handleThumbsResize(); // Initialize thumbsHeight
    window.addEventListener('resize', handleThumbsResize);

    return () => {
      window.removeEventListener('resize', handleThumbsResize);
    };
  }, [onSwiperInit]);

  useEffect(() => {
    const rootStyle = (document.querySelector('#rootApp') as HTMLElement)?.style;

    if (rootStyle) {
      rootStyle.setProperty('--thumbHeight', ((swipeSlider.thumbsHeight < 68) ? `${swipeSlider.thumbsHeight}px` : '68px'));
    }
  }, [swipeSlider.thumbsHeight]);

  const handleHandViewMobile = useCallback(() => {
    setSwipeSlider((prevState) => {
      window.postMessage({ action: 'ToggleHandView', value: !prevState.handView, color: swipeSlider.colorState });
      return {
        ...prevState,
        handView: !prevState.handView
      };
    });
  }, [setSwipeSlider, swipeSlider.colorState]);

  const handleHandView = useCallback(() => {
    setSwipeSlider((prevState) => {
      window.postMessage({ action: 'ToggleHandView', value: true, color: swipeSlider.colorState });
      return {
        ...prevState,
        handView: true
      };
    });
  }, [setSwipeSlider, swipeSlider.colorState]);

  const handleHandto3dView = useCallback(() => {
    setSwipeSlider((prevState) => {
      window.postMessage({ action: 'ToggleHandView', value: false, color: swipeSlider.colorState });
      return {
        ...prevState,
        handView: false
      };
    });
  }, [setSwipeSlider, swipeSlider.colorState]);

  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isMacOS = /Mac/.test(navigator.userAgent);
    if (isSafari && isMacOS) {
      document.body.classList.add('safari-macos');
    }
  }, []);

  const sliderThumbImage = useMemo(() => {
    return props.thumbImage ? props.thumbImage : props.img;
  }, [props.thumbImage, props.img]);

  const modules = useMemo(() => {
    return dots ? [FreeMode, Navigation, Thumbs, Pagination, A11y] : [FreeMode, Navigation, Thumbs,A11y];
  }, [FreeMode, Navigation, Thumbs, Pagination, dots, A11y]);

  const onSliderChange = useCallback((swiper: Swiper) => {
    setSwipeSlider((prevState) => {
      return {
        ...prevState,
        activeIndex: swiper.activeIndex,
        handViewImage: swiper.activeIndex === props.img.length,
        ...(!islargeTab && { handView: swiper.activeIndex === 0 })
      };
    });
    if (swiper.activeIndex === 1 && !islargeTab) {
      window.postMessage({ action: 'ToggleHandView', value: true, color: swipeSlider.colorState });
    }
  }, [handleHandView, setSwipeSlider, props.img, swipeSlider.colorState]);
  useEffect(() => {
    if (setIsHandLoaded && !swipeSlider.handView) {
      if (islargeTab) {
        if (swipeSlider.activeIndex === 0) {
          setIsHandLoaded(false);
        }
      } else {
        setIsHandLoaded(false);
      }
    }
  }, [swipeSlider.activeIndex, islargeTab,swipeSlider.handView]);
  return {
    islargeTab,
    thumbsContainerRef,
    allThumbnailRef,
    swipeSlider,
    getBackgroundColor,
    handleImageError,
    onColorSliderChange,
    handleThumbsResize,
    handleHandViewMobile,
    handleHandView,
    handleHandto3dView,
    setHandleRange,
    handleRange,
    sliderThumbImage,
    modules,
    onSliderChange,
    thumbsSwiper,
    setThumbsSwiper,
    setSwipeSlider
  };
};
export default useMainImageSlider;
