import {useCallback} from "react";
import {setStartWithSettingFilterType} from "../../types";
const CenterDiamondSize = [
    {
        "id": 1,
        "sort": null,
        "Name": "¾ ct",
        "Code": "075",
        "value": 0.75
    },
    {
        "id": 2,
        "sort": null,
        "Name": "1 ct",
        "Code": "100",
        "value": 1
    },
    {
        "id": 3,
        "sort": null,
        "Name": "1½ ct",
        "Code": "150",
        "value": 1.5
    },
    {
        "id": 4,
        "sort": null,
        "Name": "2 ct",
        "Code": "200",
        "value": 2
    },
    {
        "id": 5,
        "sort": null,
        "Name": "2½ ct",
        "Code": "250",
        "value": 2.5
    },
    {
        "id": 6,
        "sort": null,
        "Name": "3 ct",
        "Code": "300",
        "value": 3
    }
]
const useStartWithSettingFilter = ({setStartWithSettingFilter,settingFilter,removeFilterKey} : {setStartWithSettingFilter : setStartWithSettingFilterType,settingFilter:any,removeFilterKey:(value:string)=>void}) => {
    const handleFilterType = useCallback((value: string | string[], key: string) => {
        if(settingFilter.options['Color'] && key === "Metal" && value === "PT") {
            setStartWithSettingFilter('Color', 'WG');
        }

        if(settingFilter.options[key] && settingFilter.options[key] === value){
            return removeFilterKey(key);
        }
        if(key === "Center_Diamond_Type"){
         const removeOptions = settingFilter.options["Center_Diamond_Type"] === "GNU" ? ["JENNY","REVE"]  : ["LOTUS"] ;
          const UpdateRingStyle = (settingFilter.options["Ring_Style"] as string[])?.filter(style => !removeOptions.includes(style));
          if(UpdateRingStyle) {
            if(UpdateRingStyle.length > 0){
              setStartWithSettingFilter('Ring_Style',UpdateRingStyle);
            }else {
              removeFilterKey('Ring_Style');
            }
          }
        }
        setStartWithSettingFilter(key, value);
    },[setStartWithSettingFilter,settingFilter.options]);

    return {
        handleFilterType,
        CenterDiamondSize
    }
}
export default useStartWithSettingFilter;
