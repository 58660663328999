import React,{ useCallback, useMemo } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { MRT_RowData, MRT_TableInstance } from "../../types";
import { Memo_MRT_TableBody, MRT_TableBody } from "../body/MRT_TableBody";
import { MRT_TableHead } from "../head";
import { MRT_TableFooter } from "../footer";
import Loader from "../../../../../Loader/Loader";

interface Props<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
}
export const MRT_Table = <TData extends MRT_RowData>({
  table,
  ...rest
}: Props<TData>) => {
  const {
    getFlatHeaders,
    getState,
    options: {
      columnVirtualizerInstanceRef,
      columnVirtualizerOptions,
      columns,
      enableColumnVirtualization,
      enablePinning,
      enableStickyHeader,
      enableTableFooter,
      enableTableHead,
      layoutMode,
      memoMode,
      muiTableProps,
    },
    refs: { tableContainerRef },
  } = table;
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }
  const {
    isLoading,
    columnPinning,
    columnSizing,
    columnSizingInfo,
    columnVisibility,
    isFullScreen,
  } = getState();

  const tableProps =
    muiTableProps instanceof Function
      ? muiTableProps({ table })
      : muiTableProps;

  const vProps =
    columnVirtualizerOptions instanceof Function
      ? columnVirtualizerOptions({ table })
      : columnVirtualizerOptions;

  const columnSizeVars: { [key: string]: number } = useMemo(() => {
    const headers = getFlatHeaders();
    const colSizes: { [key: string]: number } = {};

    for (let i = 0; i < headers.length; i++) {
      if(columns && headers){
        const header = headers[i];
        const columnSize =  columns.find((item)=> header?.id === item.id);
        if(header?.id && (columnSize && columnSize['size'] !== undefined)){
          colSizes[`--header-${header?.id}-size`] = columnSize['size'];
          colSizes[`--col-${header?.column.id}-size`] = columnSize['size'];
        }
      }
    }
    return colSizes;
  }, [columns, columnSizing, columnSizingInfo, columnVisibility]);

  //get first 16 column widths and average them
  const averageColumnWidth = useMemo(() => {
    if (!enableColumnVirtualization) return 0;
    const columnsWidths =
      table
        .getRowModel()
        .rows[0]?.getCenterVisibleCells()
        ?.slice(0, 16)
        ?.map((cell) => cell.column.getSize()) ?? [];
    return columnsWidths.reduce((a, b) => a + b, 0) / columnsWidths.length;
  }, [table.getRowModel().rows, columnPinning, columnVisibility]);

  const [leftPinnedIndexes, rightPinnedIndexes] = useMemo(
    () =>
      enableColumnVirtualization && enablePinning
        ? [
            table.getLeftLeafColumns().map((c) => c.getPinnedIndex()),
            table
              .getRightLeafColumns()
              .map(
                (c) =>
                  table.getVisibleLeafColumns().length - c.getPinnedIndex() - 1
              ),
          ]
        : [[], []],
    [columnPinning, enableColumnVirtualization, enablePinning]
  );
  const UseVirtualizer = useVirtualizer({
    count: table.getVisibleLeafColumns().length,
    estimateSize: () => averageColumnWidth,
    getScrollElement: () => tableContainerRef.current,
    horizontal: true,
    overscan: 3,
    rangeExtractor: useCallback(
      () => [...new Set([...leftPinnedIndexes, ...rightPinnedIndexes])],
      [leftPinnedIndexes, rightPinnedIndexes]
    ),
    ...vProps,
  });
  const columnVirtualizer = enableColumnVirtualization
    ? UseVirtualizer
    : undefined;

  if (columnVirtualizerInstanceRef && columnVirtualizer) {
    columnVirtualizerInstanceRef.current = columnVirtualizer;
  }

  const virtualColumns = columnVirtualizer
    ? columnVirtualizer.getVirtualItems()
    : undefined;

  let virtualPaddingLeft;
  let virtualPaddingRight;

  if (columnVirtualizer && virtualColumns?.length) {
    virtualPaddingLeft = virtualColumns[leftPinnedIndexes.length]?.start ?? 0;
    virtualPaddingRight =
      columnVirtualizer.getTotalSize() -
      (virtualColumns[virtualColumns.length - 1 - rightPinnedIndexes.length]
        ?.end ?? 0);
  }

  const props = {
    table,
    virtualColumns,
    virtualPaddingLeft,
    virtualPaddingRight,
  };

  return (
    <>
      <table
        className={classNames(
          `headless_table-class`,
          enableStickyHeader || isFullScreen ? `sticky` : ``,
          layoutMode === "grid" ? `headless_grid` : `headless_table`,
          layoutMode !== "grid" ? `headless_table-fixed` : ""
        )}
        {...tableProps}
        style={{ ...columnSizeVars, ...tableProps?.style }}
      >
        {enableTableHead && <MRT_TableHead {...props} />}
        { isLoading ?
          <tbody>
            <tr>
              <td>
                <Loader position={'absolute'}/>
              </td>
            </tr>
          </tbody> :
          <>
            {memoMode === "table-body" || columnSizingInfo.isResizingColumn ? (
              <Memo_MRT_TableBody
                columnVirtualizer={columnVirtualizer}
                {...props}
              />
            ) : (
              <MRT_TableBody columnVirtualizer={columnVirtualizer} {...props} />
            )}
            {enableTableFooter && <MRT_TableFooter {...props} />}
          </>
        }
      </table>
    </>
  );
};
