import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { UseCartType } from '../../types';
import { ToLocalStringConvert } from '../../Utils/toLocalStringConvert';
import {colorName, DiamondLabels, weddingButton} from '../../Utils/commonData';
import ShortUniqueId from 'short-unique-id';
import {compareObjects} from "../../Utils/compareObjects";
import { nanoid } from 'nanoid';
import {getLowQualityRender} from "../../Utils/getLowQualityRender";
import {getNoBands} from "../../Utils/getNoBands";

const useCart = (props: UseCartType) => {
  const {
    ring,
    footer,
    filter,
    diamond,
    table,
    setFooterToggle,
    setRedirectEditPage,
    setSettingTabIconTrue,
    setDiamondTabIconTrue,
    setCartImageSuccess,
    setCartThumbImageSuccess,
    settingFilter,
    stateManger,
    setBackToSelectDiamondPage,
    toggleScrollTop,
    setRingOptions,
    setRingExtraData,
    setRemoveRingOption,
    setBandTabIndex,
    setFilter,
    setAfterRingOptions,
    instanceData: { screenManager },
  } = props;
  const rpid = JSON.parse(localStorage.getItem('rpid') as string);
  const builder_mode = JSON.parse(localStorage.getItem('builder_mode') as string);
  const uuid = JSON.parse(localStorage.getItem('design_uuid') as string);
  const [isShowDiamondType, setShowDiamondType] = useState(false);
  const [isShowRingType, setShowRingType] = useState(false);
  const [isShowDoubleFirstBandType, setIsShowDoubleFirstBandType] = useState(false);
  const [isShowDoubleSecondBandType, setIsShowDoubleSecondBandType] = useState(false);
  const [image,setImage] = useState<string[]>([]);
  const [thumbImage,setThumbImage] = useState<string[]>([]);
  const [randomPrice,setRandomPrice] = useState<number>(0);
  const [showBandData,setShowBandData] = useState<{[key:string]:string|{[key:string]:string}}>({
    FirstBandMetalColor:'',
    FirstBand:{},
    SecondBandMetalColor:'',
    SecondBand:{}
  })
  const {
    certificate_link,
    scs_certificate_link,
    certification,
    source,
    carat,
    shape,
    cut,
    color,
    clarity,
    measurement,
    table_per,
    depth,
    symmetry,
    polish,
    girdle,
    culet,
    fluorecence,
      b2c_price:diamond_b2c_price,
  } = diamond.details;
  const { Ring_Crown, Ring_Side, Ring_Style, Color: ringColor, Metal, Ring_Size, Engraving } = ring.selectedRingDetails;
  const { Single_First_Metal_Band, Double_First_Metal_Band, Double_Second_Metal_Band, First_Band_Engraving, Second_Band_Engraving,Wedding_Band } =
    ring.options;
  const {ring_price, left_band_price, right_band_price} = ring.ringPrice;
  const ringPrice = +ring_price /*+ (ring.options.Wedding_Band !== 'No Band' ? ring.options.Wedding_Band === 'Single' ? +left_band_price : +(left_band_price+right_band_price)  :0)*/;
  // const [lowQualityData,setLowQualityData] = useState<{[key:string]:boolean}>({});

  /*const lowQuality = useMemo(async ()=>{
    const { Ring_Style, Ring_Crown,Ring_Side } = ring.options;
    return await getLowQualityRender(Ring_Style, Ring_Crown, Ring_Side)
  },[setImage,setThumbImage,ring.options["Ring_Style"],ring.options["Ring_Crown"],ring.options["Diamond_Shape"],ring.options["Center_Diamond_Size"],ring.options["Ring_Side"],ring.options["Metal"],ring.options["Color"],ring.options["Diamond_Type"],ring.options["Wedding_Band"],ring.options["Single_First_Metal_Band"],ring.options["Double_Second_Metal_Band"],ring.options["Double_First_Metal_Band"]])*/

  useEffect(()=>{
     const getNoBandsData =async()=>{
      if(ring.options["Ring_Style"] && ring.options["Ring_Crown"] && ring.options["Diamond_Shape"] && ring.options["Ring_Side"] && ring.extraData.Label !== '' && ring.extraData.Label){
        const extraData = await getNoBands(ring.extraData, setRingExtraData, ring.options.Ring_Style, ring.options.Ring_Crown, ring.options.Ring_Side,ring.options.Diamond_Shape);
        // setRingOptions('Wedding_Band', extraData?.Bands === 0 ? 'No Band':extraData?.Bands === 1 ? 'Single' : 'Double');
        if(extraData?.Bands === 0){
          setBandTabIndex(0);
          setRingOptions('Wedding_Band','No Band');
          ['Single_First_Band','Single_First_Metal_Band','Double_First_Band','Double_First_Metal_Band','Double_Second_Band','Double_Second_Metal_Band'].map((value)=> setRemoveRingOption(value))
        }else if(extraData?.Bands === 1){
            if(Wedding_Band === 'Double'){
              setBandTabIndex(1);
              setRingOptions('Wedding_Band','Single');
              ['Double_First_Band','Double_First_Metal_Band','Double_Second_Band','Double_Second_Metal_Band'].map((value)=> setRemoveRingOption(value));
            }
        }
      }
    }
    getNoBandsData();
  },[ring.options["Ring_Style"],ring.options["Ring_Crown"],ring.options["Diamond_Shape"],ring.options["Ring_Side"],diamond.details['Center_Diamond_Shape']])

  useEffect(()=>{
    const fetchData = async ()=>{
      const { Ring_Style, Ring_Crown, Center_Diamond_Size,Ring_Side, Diamond_Shape, Color, Single_First_Metal_Band, Double_First_Metal_Band, Double_Second_Metal_Band,Wedding_Band } = ring.options;
      if(footer.styleID && Ring_Crown && Diamond_Shape && Center_Diamond_Size && Ring_Side){
        const imageSKU = `${Ring_Style}-${Ring_Crown}-${Ring_Side}`;
        // const getLowQualityImage = await lowQuality;
        setImage([]);
        setThumbImage([]);

        const style = footer.styleID.includes('RP') ? `${footer.styleID}-${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}` : `${footer.styleID}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}`;
        const uniqueName = footer.styleID.includes('RP') ? `${footer.styleID}${Ring_Crown}${Diamond_Shape}${Center_Diamond_Size}${Ring_Side}` : `${footer.styleID}${Ring_Style}${Ring_Crown}${Diamond_Shape}${Center_Diamond_Size}${Ring_Side}${Color}${Color}${(Single_First_Metal_Band || Double_First_Metal_Band ) ? `${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `${Double_Second_Metal_Band?.split('_')[1]}` : '' }${Wedding_Band === "Double" ? 'C1': Wedding_Band === "Single" ? 'C2': 'C3'}`;
        // const imageViews = getLowQualityImage && getLowQualityImage[imageSKU] ? ["V1", "V2", "V3", "V4","V5"] : ["V1", "V2", "V3", "V4"];

        ["V1", "V2", "V3", "V4", "V5"].forEach((view: string) => {
          const imagePath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${Color}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band ) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : '' }/${Wedding_Band === "Double" ? 'C1': Wedding_Band === "Single" ? 'C2': 'C3'}/${view}/2000/fafafa/lq/${uniqueName}${view}2000.jpg`;
          const thumbPath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${Color}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band ) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : '' }/${Wedding_Band === "Double" ? 'C1': Wedding_Band === "Single" ? 'C2': 'C3'}/${view}/150/fafafa/lq/${uniqueName}${view}150.jpg`;
          setImage((prev: string[]) => {
            return [...prev, imagePath];
          });
          setThumbImage((prev: string[]) => {
            return [...prev, thumbPath];
          });
        });
      }
    };
    fetchData();
  },[ring.options["Ring_Style"],ring.options["Ring_Crown"],ring.options["Diamond_Shape"],ring.options["Center_Diamond_Size"],ring.options["Ring_Side"],ring.options["Metal"],ring.options["Color"],ring.options["Diamond_Type"],ring.options["Wedding_Band"],ring.options["Single_First_Metal_Band"],ring.options["Double_Second_Metal_Band"],ring.options["Double_First_Metal_Band"]])

  useEffect(()=>{
    setCartImageSuccess(image);
  },[image])

  useEffect(()=>{
    setCartThumbImageSuccess(thumbImage);
  },[thumbImage])

  const showDiamondDetails = {
    'Certificate Link':certificate_link,
    'SCS Certificate Link':scs_certificate_link,
    certification:certification,
    'Diamond Type': source,
    'Carat Weight': carat,
    Shape: shape,
    Cut: cut,
    Color: color,
    Clarity: clarity,
    Measurements: measurement,
    Table: `${table_per ? `${table_per}%` : ''}`,
    Depth: `${depth ? `${depth}%` : ''}`,
    Symmetry: symmetry,
    Polish: polish,
    Girdle: girdle,
    Culet: culet,
    Fluorecence: fluorecence,
  };
  const showRingDetails = {
    'Ring Style': Ring_Style,
    'Ring Crown': Ring_Crown,
    'Ring Side': Ring_Side,
    ...(ring.ringPrice.ring_tcw && {'Minimum Setting Total Weight' :  `${ring.ringPrice.ring_tcw.toFixed(2)} ct.`}),
    Metal: `${Metal} ${ringColor ? ringColor : ''} ${Metal?.toLowerCase() !== "platinum" ? 'Gold': ''}`,
    'Ring Size': Ring_Size,
  ...(Engraving && {Engraving: Engraving})
  };
  const FirstBandMetalColor = weddingButton.find(btn => btn.Code === (Single_First_Metal_Band || Double_First_Metal_Band))?.Name;
  const FirstBand = {
    ...(Wedding_Band !== 'No Band' && ring.ringPrice.bands_tcw && {'Minimum Band Total Weight' : Wedding_Band === 'Single'? `${ring.ringPrice.bands_tcw.toFixed(2)} ct.` : `${(ring.ringPrice.bands_tcw/2).toFixed(2)} ct.`}),
    Metal: FirstBandMetalColor ? `${FirstBandMetalColor} ${FirstBandMetalColor?.split(' ')[0]?.toLowerCase() !== "platinum" ? 'Gold': ''}` : '',
    ...(First_Band_Engraving && {engraving: First_Band_Engraving})
  };
  const SecondBandMetalColor = weddingButton.find(btn => btn.Code === Double_Second_Metal_Band)?.Name;
  const SecondBand = {
    ...(Wedding_Band === 'Double' && ring.ringPrice.bands_tcw && {'Minimum Band Total Weight' : `${(ring.ringPrice.bands_tcw/2).toFixed(2)} ct.`}),
    Metal: SecondBandMetalColor ? `${SecondBandMetalColor} ${SecondBandMetalColor?.split(' ')[0]?.toLowerCase() !== "platinum" ? 'Gold': ''}`:'',
    ...(Second_Band_Engraving && {engraving: Second_Band_Engraving}),
  };

  const handleDiamondAction = () => {
    setShowDiamondType((type) => !type);
  };
  const handleRingAction = () => {
    setShowRingType((type) => !type);
  };
  // Function of Finalize or Update Product Data
  const handleReviewClick= async() =>{
    const endpoint = process.env.REACT_APP_DESIGN_DATA_UPLOAD as string;
    const uid = new ShortUniqueId({ length: 10 });

    const {Ring_Style,Ring_Side,Ring_Crown,Diamond_Shape,Center_Diamond_Size,Metal,Color,Diamond_Type,Wedding_Band} = ring.options;
    const ringSku = `${footer.styleID}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}-${Metal}-${Color}-${Diamond_Type}`
    const {diamond_details,setting_details,ring_options_code,name,sku,diamond_filter_options,setting_filter_options,ring_b2c_price,ring_b2b_cost,table_row_id,ring_extra_data} = stateManger.editData ;
    const oldEditData = {
      diamond_details,ring_options_code,sku,setting_filter_options,ring_b2b_cost,table_row_id
    }

    const newNames :{[key:string]:string}={
      ring_name:generateName('ringName') as string,
      diamond_name:generateName('diamondName') as string,
      setting_name:generateName('settingName') as string
    }

    if(Wedding_Band === 'Single'){
      newNames["left_band_name"] = generateName('leftBandName') as string;
    }else if (Wedding_Band === 'Double'){
      newNames["left_band_name"] = generateName('leftBandName') as string;
      newNames["right_band_name"] = generateName('rightBandName') as string;
    }

    const filteredExpandedRow = Object.fromEntries(Object.entries(table.expandRow).filter(([_, value]) => value !== null && value !== ""));
    const newEditData = {
      diamond_details: diamond.details,
      ring_options_code:ring.options,
      sku:ringSku,
      setting_filter_options:settingFilter.options,
      ring_b2b_cost:111,
      table_row_id:filteredExpandedRow,
    }

    /*const getLowQualityImage = await lowQuality;
    const imageSKU = `${Ring_Style}-${Ring_Crown}-${Ring_Side}`*/
    const style = footer.styleID.includes('RP') ? `${footer.styleID}-${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}` : `${footer.styleID}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}`;
    const isSameObjects = compareObjects(oldEditData,newEditData);
    let returnUrl:string;
    if(!isSameObjects){
      const result = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({
          ...newEditData,
          names:newNames,
          setting_details: {...ring.selectedRingDetails , Ring_Metal_Color : showRingDetails.Metal?.trim() , ...{Ring_First_Band_Metal : FirstBand.Metal ? FirstBand.Metal?.trim():'' }, ...{Ring_Second_Band_Metal : SecondBand.Metal ? SecondBand.Metal?.trim() : ''}},
          ring_extra_data:{...ring.extraData,exclusionOption : ring.exclusionOption},
          image_code:`${style}/${Color}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band ) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : '' }/${Wedding_Band === "Double" ? 'C1': Wedding_Band === "Single" ? 'C2': 'C3'}/`,
          ring_b2c_price:+ringPrice,
          diamond_filter_options:{options : filter.options , afterRingSelectedOptions: filter.afterRingSelectedOptions},
          action:builder_mode === 'edit'? 'update' :'insert',
          ...(builder_mode === 'edit') && { short_uuid: uuid},
        }),
      })
      if(result.ok){
        let design_data ;
        design_data = await result.json();
        if(design_data){
          if(builder_mode === 'build'){
            returnUrl = `${window.location.origin}${process.env.REACT_APP_REDIRECT_URL_PREFIX}pid=${rpid}&hkuuid=${await design_data.short_uuid}` ;
          }
          else if(builder_mode === 'edit'){
            const uuid = JSON.parse(localStorage.getItem('design_uuid') as string);
              returnUrl = `${window.location.origin}${process.env.REACT_APP_REDIRECT_URL_PREFIX}pid=${rpid}&hkuuid=${uuid}&action=updated`;
          }
        }
      }
    }
    if(isSameObjects) {
      returnUrl = `${window.location.origin}${process.env.REACT_APP_REDIRECT_URL_PREFIX}pid=${rpid}&hkuuid=${uuid}`;
    }
    ['rpid','builder_mode','design_uuid'].forEach(key =>localStorage.removeItem(key));
    // @ts-ignore
    window.location.href = returnUrl;
  }
  // Generating Name of All Ring Parts
  const generateName = useCallback((name:string)=>{
    const {Wedding_Band} = ring.options;
    const {ring_tcw} = ring.ringPrice;
    const {Diamond_Type, Diamond_Shape, Ring_Style, Color, Metal} = ring.selectedRingDetails;
    let DiamondSize = ring.optionsData.Center_Diamond_Size && ring.optionsData.Center_Diamond_Size.find((size: any) => size.Code === ring.options.Center_Diamond_Size);
    let centerDiamondSize =  (diamond.details && diamond.details.carat) ? diamond.details.carat : DiamondSize !== undefined ? DiamondSize.Code/100 : ring.options.Center_Diamond_Size;
    if (Object.keys(ring.selectedRingDetails).length > 0) {
      if(name === 'ringName'){
        return `${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${Ring_Style} ${Diamond_Type} Diamond ${DiamondLabels[(shape ? shape : Diamond_Shape) as string]} Engagement Ring${Wedding_Band !== 'No Band' ? Wedding_Band === 'Single' ? ' with Wedding Band' : ' with Wedding Bands' : ''}`.trim();
        // return `${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${Ring_Style} ${Diamond_Type} Diamond ${DiamondLabels[Diamond_Shape as string]} Engagement Ring in ${Metal}${Color ? ` ${Color}` : ''}${Metal?.toLowerCase() !== "platinum" ? ' Gold' : ''} (${ring_tcw ? (+ring_tcw + (+centerDiamondSize)) : centerDiamondSize} ct. tw.) ${Wedding_Band !== 'No Band' ? Wedding_Band === 'Single' ? 'with Wedding Band' : 'with Wedding Bands' : ''}`.trim();
      }else if(name === 'diamondName'){
        return `${carat} ct. ${DiamondLabels[shape as string]} ${source} Diamond`;
      }else if(name === 'settingName'){
        return `${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${Ring_Style} ${source} Diamond ${DiamondLabels[shape as string]} Engagement Ring in ${Metal}${Color ? ` ${Color}` : ''}${Metal?.toLowerCase() !== "platinum" ? ' Gold' : ''} (${ring_tcw ? (+ring_tcw + (+centerDiamondSize)).toFixed(2) : (+centerDiamondSize).toFixed(2)} ct. tw.)`.trim()
        // return `${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${Ring_Style} ${source} Diamond ${DiamondLabels[shape as string]} Engagement Ring in ${Metal}${Color ? ` ${Color}` : ''}${Metal?.toLowerCase() !== "platinum" ? ' Gold' : ''}`.trim()
      }else if(name === 'leftBandName'){
        return `${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${source} Diamond Wedding Band in ${FirstBandMetalColor} ${FirstBandMetalColor?.split(' ')[0]?.toLowerCase() !== "platinum" ? 'Gold': ''}`.trim();
      }else if(name === 'rightBandName'){
        return `${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${source} Diamond Wedding Band in ${SecondBandMetalColor} ${SecondBandMetalColor?.split(' ')[0]?.toLowerCase() !== "platinum" ? 'Gold': ''}`.trim();
      }
    }

  },[ring.selectedRingDetails,ring.options,ring.optionsData,ring.ringPrice])

  const editDiamondAction = useCallback(() => {
    toggleScrollTop(true);
    setFooterToggle();
    setSettingTabIconTrue();
    setRedirectEditPage(true);
    setBackToSelectDiamondPage(true);
    const {Diamond_Shape,Diamond_Type} = ring.options;
    if(ring.extraData.Label && ring.extraData.Label !== '' && !Object.keys(filter.options).includes('source')){
      if(ring.extraData.Label !== 'Generic'){
        setFilter("source",'LGN');
        const Options = {
          source:Diamond_Type,
          shape:Diamond_Shape
        }
        setAfterRingOptions(Options);
      }
    }
    (screenManager as any).changeScreen({ viewName: 'diamond', id: null });
  }, [toggleScrollTop,setFooterToggle, setSettingTabIconTrue, setRedirectEditPage,screenManager,setBackToSelectDiamondPage,ring.extraData,filter.options,setFilter,setAfterRingOptions,ring.options]);

  const editRingAction = useCallback(() => {
    setFooterToggle();
    setDiamondTabIconTrue('dyo');
    setRedirectEditPage(true);
    if (Object.keys(diamond.details).length > 0) {
      (screenManager as any).changeScreen({
        viewName: 'dyo',
        styleId: footer.styleID,
        id: diamond.details.id,
      });
    } else {
      (screenManager as any).changeScreen({
        viewName: 'dyo',
        styleId: footer.styleID,
        id: null,
      });
    }
  }, [setFooterToggle, setDiamondTabIconTrue, setRedirectEditPage, screenManager, diamond.details,footer.styleID]);

  const handleDoubleFirstBandAction = useCallback(() => {
    setIsShowDoubleFirstBandType((type) => !type);
  }, [setIsShowDoubleFirstBandType]);

  const handleDoubleSecondBandAction = useCallback(() => {
    setIsShowDoubleSecondBandType((type) => !type);
  }, [setIsShowDoubleSecondBandType]);
  useEffect(()=>{
    setRandomPrice(Math.floor(Math.random() * (200 - 100 + 1)) + 100)
  },[])
  const {Label} =ring.extraData;
  const allDetails = [
    {
      showDetails: Object.keys(diamond.details).length > 0 ? showDiamondDetails : [],
      title: `${carat} ct. ${DiamondLabels[shape as string]} ${source} Diamond`.trim(),
      price: `$${ToLocalStringConvert(diamond_b2c_price ? +diamond_b2c_price : 0)}`,
      editAction: editDiamondAction,
      moreAction: handleDiamondAction,
      isShowMore: isShowDiamondType,
    },
    {
      showDetails: Object.keys(ring.selectedRingDetails).length > 0 ? showRingDetails : [],
      title: `${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${Ring_Style} ${source} Diamond Setting in ${Metal} ${ringColor ? ringColor : ''} ${Metal?.toLowerCase() !== "platinum" ? 'Gold': ''}`.trim(),
      price: `$${ToLocalStringConvert(+ring_price - +left_band_price - +right_band_price)}`,
      editAction: editRingAction,
      moreAction: handleRingAction,
      isShowMore: isShowRingType,
    },
    {
      showDetails: Double_First_Metal_Band || Single_First_Metal_Band ? FirstBand : [],
      title:`${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ': `${Label} `) : ''}${source} Diamond Wedding Band in
      ${FirstBandMetalColor} ${FirstBandMetalColor?.split(' ')[0]?.toLowerCase() !== "platinum" ? 'Gold': ''}`.trim(),
      price: `$${ToLocalStringConvert(+left_band_price)}`,
      editAction: editRingAction,
      moreAction: handleDoubleFirstBandAction,
      isShowMore: isShowDoubleFirstBandType,
    },
    {
      showDetails: Double_Second_Metal_Band ? SecondBand : [],
      title: `${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${source} Diamond Wedding Band in
      ${SecondBandMetalColor} ${SecondBandMetalColor?.split(' ')[0]?.toLowerCase() !== "platinum" ? 'Gold': ''}`.trim(),
      price: `$${ToLocalStringConvert(+right_band_price)}`,
      editAction: editRingAction,
      moreAction: handleDoubleSecondBandAction,
      isShowMore: isShowDoubleSecondBandType,
    },
  ];

  return {
    rpid,
    uuid,
    randomPrice,
    builder_mode,
    allDetails,
    Ring_Style,
    diamond_b2c_price,
    handleReviewClick,
    ringPrice,
    Label
  };
};

export default useCart;
