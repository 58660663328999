import {allSwatchValue, PDPBuilderType, PropsFromScreenManager, TableData} from "../../types";
import {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {iFrameHost} from "../BuildOwn/BuildOwn";
import {useNavigate} from "react-router-dom";
import {propertyMappings, source} from "../Diamond/useDiamond";
import {TooltipDataFetch} from "../Tooltip/TooltipDataFetch";
import {buildYourOwnValue, findNearestValue, startWithSettingValue} from "../../Utils/findNearestCaratValue";
import {getRingPrice} from "../../Utils/getRingPriceValue";
import {getDiamondCode} from "../../Utils/getDiamondCode";
import {getNoBands} from "../../Utils/getNoBands";
import { exclusionMatrixData, getExclusionOptions } from '../../Utils/getExclusionOptions';
import { initialExclusionKey } from '../../Utils/initialExclusionKey';

const usePdpBuilder = (props: PDPBuilderType & PropsFromScreenManager) => {
  const {
    ring,
    diamond,
    footer,
    settingFilter,
    setSelectedRingDetails,
    setSelectedDiamondLoading,
    setSelectedDiamondData,
    setSelectedDiamondFail,
    setRingOptions,
    setOptionsLoading,
    setOptionsData,
    setOptionsFail,
    setDiamondDetailsLoading,
    setDiamondDetailsFail,
    setDiamondDetailsSuccess,
    SetTooltipDataSuccess,
    setTooltipDataLoading,
    SetTooltipDataError,
    setStyleID,
    setIframeLoadActionCount,
    setPriceLoading,
    setRingPrice,
    setRingPriceError,
    setRingExtraData,
    setBandTabIndex,
    setIsShowFooter,
    setFooterToggle,
    setAfterRingOptions,
    setSettingTabIconTrue,
    setExclusionOption,
    setRedirectEditPage,
    setRemoveRingOption,
    setIsHandLoaded,
    instanceData: {screenManager},
  } = props;

  const navigate = useNavigate();
  const currentUrl = window.location.href;

  const match_styleId = currentUrl.match(/styleId=([^&]*)/);
  const match_diamondId = currentUrl.match(/diamondId=([^&]*)/);
  const styleId = match_styleId?.[1].split('/')[0] || '';
  const diamondId = match_diamondId?.[1] || '';
  const [showError, setShowError] = useState<boolean>(false);

  const [productName, setProductName] = useState<string>('')
  const hasEffectRun = useRef(false);
  const [fieldValidation, setFieldValidation] = useState<string[]>([])
  const rpid = localStorage.getItem('rpid');
  const builder_mode = localStorage.getItem('builder_mode');
  const uuid = localStorage.getItem('design_uuid');
  const [weddingBand, setWeddingBand] = useState<string[]>(['No Band', 'Single', 'Double'])
  // const weddingBand = ['No Band','Single','Double'];
  const {ring_price, left_band_price, right_band_price} = ring.ringPrice;
  const [stack, setStack] = useState(0);
  const [exclusionMatrix, setExclusionMatrix] = useState<{ [key: string]: string }[]>([]);
  const ringPrice = useMemo(()=>{
    if(ring_price){
      return +ring_price
    }
    return 0
  },[ring_price]) /*+ (ring.options.Wedding_Band !== weddingBand[0] ? ring.options.Wedding_Band === weddingBand[1] ? left_band_price : (left_band_price+right_band_price)  :0)*/;

  useEffect(() => {
    if (ring.extraData.Bands === 2) {
      setWeddingBand(['No Band', 'Single', 'Double']);
    } else if (ring.extraData.Bands === 1) {
      setWeddingBand(['No Band', 'Single']);
    }
  }, [ring.extraData.Bands])

  useEffect(() => {
    const {Diamond_Type, Diamond_Shape, Ring_Style, Color, Metal} = ring.selectedRingDetails;
    const {Label} = ring.extraData;
    if (Object.keys(ring.selectedRingDetails).length > 0) {
      setProductName(`${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${Ring_Style} ${Diamond_Type} Diamond Setting in ${Metal} ${Color ? Color : ''} ${Metal?.toLowerCase() !== "platinum" ? 'Gold': ''} ${ring.options.Wedding_Band !== 'No Band' ? ring.options.Wedding_Band === 'Single' ? ' with Wedding Band' : ' with Wedding Bands' : ''}`)
    }
  }, [ring.selectedRingDetails, ring.extraData,ring.options])

  useEffect(() => {
    setStyleID(styleId as string)
  }, [styleId])

  useEffect(() => {
    if(Object.keys(ring.options).length > 0 && Object.keys(ring.optionsData).length > 0){
      Object.entries(ring.options).map(([key, value]) => {
        const allValue = ring.optionsData[!["Metal","Color"].includes(key) ? key : "Metal_Color"];
          if (allValue) {
            const findSwatchValue = (allValue as allSwatchValue[]).find(
              (a) =>  !["Metal","Color"].includes(key) ? a.Code === value : a.Code === `${ring.options.Metal}_${ring.options.Color}`
            );
            if (findSwatchValue) {
              if(["Metal","Color"].includes(key)){
                const metalColor = {
                  "Metal" : (findSwatchValue.Name as string)?.split(' ')[0],
                  "Color" : (findSwatchValue.Name as string)?.split(' ')[1],
                }
                Object.entries(metalColor).forEach(([key,value]) => setSelectedRingDetails(key, value))
              } else {
                setSelectedRingDetails(
                  key,
                  findSwatchValue.Name as string
                );
              }

            }
          }
      });
      if ("Engraving" in ring.options) {
        setSelectedRingDetails("Engraving", ring.options["Engraving"])
      }
    }
  }, [ring.options,ring.optionsData]);
  useEffect(() => {
    if (Object.keys(ring.options).length > 0) {
      const {Ring_Style, Ring_Side, Metal, Color} = ring.options;
      if (Ring_Style && Ring_Side && Metal && Color) {
        const {Ring_Crown, Diamond_Shape, Center_Diamond_Size, Diamond_Type, Wedding_Band} = ring.options;
        // const ringPriceData = `${styleId}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}-${Metal}-${Color}-${Diamond_Type}`;
        const ringPriceData = `${styleId}-${Ring_Style}${Ring_Crown}-RND-200-${Ring_Side}-${Metal}-WG-${Diamond_Type}`;
        if(ringPriceData && Wedding_Band){
          const priceData = {
            sku: `'${ringPriceData}'`, order_items: Wedding_Band === "No Band" ? 1 : Wedding_Band === "Single" ? 2 : 3
          }
          getRingPrice(setPriceLoading, setRingPrice, setRingPriceError, priceData);
        }
      }
    }
  }, [ring.options["Ring_Style"], ring.options["Ring_Crown"], ring.options["Diamond_Shape"], ring.options["Center_Diamond_Size"], ring.options["Ring_Side"], ring.options["Metal"], ring.options["Color"], ring.options["Diamond_Type"], ring.options["Wedding_Band"]])

  const [pendingEvents, setPendingEvents] = useState<{ [key: string]: string | boolean }[]>([]);
  const iframeAction = useRef<{ [key: string]: string | boolean }[]>(pendingEvents);

  useEffect(() => {
    iframeAction.current = pendingEvents;
  }, [pendingEvents])

  useEffect(() => {
    const afterDesignLoaded = ["ChangeRingColor", "LeftBand", "RightBand", "ChangeLeftBandColor", "ChangeRightBandColor"];
    let isHandShakeReturn = false;
    const handleMessageEvent = (event: MessageEvent<any>) => {
      //@ts-ignore
      const origin = event.origin || event?.originalEvent.origin;
      if (origin === window.location.origin) {
        if (event.data.action === 'HandshakeIntegration') {
          window.postMessage({
            action: "HandshakeInternal"
          });
        }
        if (event.data.action === 'HandshakeReturnInternal') {
          isHandShakeReturn = true;
        }
        if (isHandShakeReturn) {
          iframeAction.current.filter(model => !afterDesignLoaded.includes(model.action as string)).forEach((data) => {
            window.postMessage(data);
          });
          isHandShakeReturn = false;
        }
      }
      if (origin === iFrameHost) {
        if (event.data.action === 'DesignLoaded') {
          afterDesignLoadEvent(iframeAction, afterDesignLoaded)
        }
        if(event.data.action === "HandLoaded"){
          setIsHandLoaded(true);
        }
      }
      if (event.data.action === 'DesignLoadError') {
        afterDesignLoadEvent(iframeAction, afterDesignLoaded)
      }
      /*if(event.data.action === "DownloadFile"){
        set3DImageSuccess(event.data.imageUrl);
      }*/
    };
    if (iframeAction.current.length > 0) {
      // console.log(iframeAction.current)
      window.addEventListener("message", handleMessageEvent);
      window.postMessage({
        action: "HandshakeInternal"
      });
    }
    return () => {
      if (iframeAction.current.length > 0) {
        // console.log("REMOVE EVENT LISTENER")
        window.removeEventListener("message", handleMessageEvent);
      }
    };
  }, [iframeAction.current]);
  const afterDesignLoadEvent = (iframeAction: React.MutableRefObject<{
    [p: string]: string | boolean
  }[]>, afterDesignLoaded: string[]) => {
    setIframeLoadActionCount(count => {
      const updatedCount = count - 1;
      if (updatedCount === 0) {
        iframeAction.current.filter(model => afterDesignLoaded.includes(model.action as string)).forEach((data) => {
          window.postMessage(data);
        });
        window.postMessage({
          action: 'SwitchCameraView'
        })
      }
      return updatedCount;
    });
  }
  const handle3DObjectView = useCallback((key: string, value: string | boolean) => {
    setPendingEvents((prev) => {
      return [...prev, {action: key, value}];
    });
  }, [setPendingEvents]);
  const optionsData = useCallback(async (selectedDiamond: { [key: string]: string }, shape: string | null) => {
    setOptionsLoading();
    try {
      const response = await fetch(`${process.env.REACT_APP_PDP_OPTIONS_KEY}?ring_builder=${styleId}`);
      if (!response.ok) {
        setOptionsFail(new Error(`HTTP error! Status: ${response.status}`));
      }
      const result = await response.json();
      const {
        Diamond_Type,
        Diamond_Shape,
        Ring_Crown,
        Ring_Side,
        Ring_Style,
        Ring_Size,
        Metal_Color,
        Center_Diamond_Size,
        Extra_Data
      } = result;
      const newResult = {
        Ring_Style,
        Diamond_Shape,
        Diamond_Type,
        Center_Diamond_Size,
        Ring_Crown,
        Ring_Side,
        Metal_Color,
        Ring_Size
      };
      const Exclusion_Matrix = await exclusionMatrixData(styleId);
      setExclusionMatrix(Exclusion_Matrix);
      const modelInitialData: { [key: string]: string } = {};
      if ((!(rpid && uuid) && builder_mode !== "edit") || !(Object.keys(ring.options).length > 0)) {
        Object.entries(newResult).forEach(([key, value]) => {
          if (["Ring_Style", "Ring_Crown", "Center_Diamond_Size", "Ring_Side"].includes(key)) {
            modelInitialData[key] = key === "Center_Diamond_Size" ? (selectedDiamond["Center_Diamond_Size"] || (styleId.includes('RP') ? ring.options[key] || settingFilter.options[key] || "200" : ring.options[key] || value[0].Code)) : (ring.options[key] || settingFilter.options[key] || value[0].Code)
          }
          if (key !== "Ring_Size") {
            switch (key) {
              case "Metal_Color":
                modelInitialData["ChangeRingColor"] = ring.options["Color"] || settingFilter.swatchColor || settingFilter.options["Color"] || settingFilter.productOptions["Color"] || value[0].Code?.split('_')[1] ;
                break;
              case 'Diamond_Shape':
                modelInitialData[key] = ring.options[key] || selectedDiamond[key] || settingFilter.options[key] || settingFilter.productOptions[key] || value[0].Code;
                break;
            }
            if (![...Object.keys(selectedDiamond),"Metal_Color"].includes(key)) {
              const defaultValue =
                key === "Center_Diamond_Size"
                  ? (styleId.includes('RP') ? ring.options[Center_Diamond_Size] || settingFilter.options.Center_Diamond_Size || "200" : value[0].Code)
                  : key === "Diamond_Shape" ? (ring.options["Diamond_Shape"] || settingFilter.options["Diamond_Shape"] || settingFilter.productOptions["Diamond_Shape"] || value[0].Code)
                  : Object.keys(settingFilter.options).includes(key)
                    ? (key === 'Ring_Style')
                            ? value[0].Code
                            :ring.options[key] || settingFilter.options[key]
                    : (key === 'Diamond_Type' && Object.keys(settingFilter.options).includes('Center_Diamond_Type'))
                          ? settingFilter.options['Center_Diamond_Type']
                          : key === 'Diamond_Type' ? 'LGN' : value[0].Code;
              modelInitialData[key] = defaultValue;
              return setRingOptions(key, defaultValue);
            } else {
              if(key === "Metal_Color"){
                const specialCaseMetal = ["Jenny Packham"].includes(Extra_Data.Label) ? '18' : undefined ;
                const metalColor = {
                  "Metal" : specialCaseMetal || ring.options["Metal"] || settingFilter.options["Metal"] || settingFilter.productOptions["Metal"] || value[0].Code?.split('_')[0],
                  "Color" : ring.options["Color"] || settingFilter.swatchColor || settingFilter.options["Color"] || settingFilter.productOptions["Color"] || value[0].Code?.split('_')[1]
                }
                Object.entries(metalColor).forEach(([key,value]) => setRingOptions(key, value))
              }
            }
          }
        });
        const extraData = await getNoBands(Extra_Data, setRingExtraData, modelInitialData.Ring_Style, modelInitialData.Ring_Crown, modelInitialData.Ring_Side,modelInitialData.Diamond_Shape);
        const selectedDiamondOptions: { [key: string]: string } = {};
        const selectedOptions: string[] = [];
        if (Object.keys(selectedDiamond).length > 0) {
          ["Diamond_Shape", "Center_Diamond_Size"].forEach(item => selectedOptions.push(item));
          Object.entries(selectedDiamond).filter(([key, value]) => selectedOptions.includes(key)).forEach(([key, value]) => {
            selectedDiamondOptions[key] = value;
          })
        }
        const selectedExclusionData: { [key: string]: string }[] = Exclusion_Matrix.filter((item: {
          [key: string]: string
        }) => {
          return Object.keys(selectedDiamondOptions).every(key => item[key] === selectedDiamondOptions[key])
        });
        const ringExclusionMatrix = getExclusionMatrixOptions(selectedOptions, selectedExclusionData, modelInitialData,newResult);
        excludedOptions([...Object.values(ringExclusionMatrix).flat()], modelInitialData, newResult, true, extraData && extraData.Bands ? extraData.Bands > 0 : false, Exclusion_Matrix, selectedDiamondOptions)
        if (!footer.isRedirectToEdit) {
          setRingOptions('Wedding_Band', 'No Band');
        }
      }
      setOptionsData(newResult);
    } catch (e) {
      setOptionsFail(e);
    }
  }, [footer.isRedirectToEdit,setRingExtraData,setOptionsLoading, setOptionsFail, setOptionsData, fetch, setRingOptions, handle3DObjectView,settingFilter.options,settingFilter.productOptions]);
  const handlePDPOptions = useCallback((key: string, value: string) => {
    setRingOptions(key, value);
  }, [setRingOptions]);

  const getExclusionMatrixOptions = (selectedOptions: string[], selectedExclusionData: {
    [key: string]: string
  }[], ringOptions: { [key: string]: string },allOptionsData : any) => {
    const ringExclusionMatrix: { [key: string]: string[] } = {};
    Object.keys(exclusionMatrixOption).forEach(option => {
      if (!selectedOptions.includes(option)) {
        const otherExclusionOption = Object.keys(exclusionMatrixOption).filter(item => !selectedOptions.includes(item)).filter(item => item !== option);
        const filteredData = selectedExclusionData.filter(data => {
          return otherExclusionOption.every(k => {
            return data[k] === ringOptions[k];
          });
        });
        if(filteredData.map(data => data[option]).includes(ringOptions[option])){
          const getValue = Object.entries(allOptionsData).map(([ringKey, ringValue]) => {
            if (ringKey === option) return ringValue;
          }).filter(item => item);
          const firstValue = getValue.flat().find(item => !filteredData.map(data => data[option]).includes((item as { Code: string }).Code));
          if(firstValue){
            ringOptions[option] = (firstValue as {Code : string}).Code;
            setRingOptions(option,(firstValue as {Code : string}).Code)
          }
        }
        ringExclusionMatrix[option] = filteredData.map(data => data[option]);
      } else {
        ringExclusionMatrix[option] = [];
      }
    });
    setExclusionMatrixOption(ringExclusionMatrix);
    return ringExclusionMatrix;
  }
  const selectedData = useCallback(async (diamondDetail: { [key: string]: string }, carat: string) => {
    const result = await getDiamondCode(diamondDetail, setSelectedDiamondLoading, setSelectedDiamondFail);
    if (result) {
      setSelectedDiamondData({...result, Center_Diamond_Size: findNearestValue(+carat)});
      optionsData({...result, Center_Diamond_Size: findNearestValue(+carat)}, diamondDetail.shape);
    }
  }, [URLSearchParams, setSelectedDiamondLoading, setSelectedDiamondData, setSelectedDiamondFail, optionsData, fetch]);
  const DetailsData = useCallback(async (id: string) => {
    setDiamondDetailsLoading();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/?id='${id}'`);
      if (!response.ok) {
        setDiamondDetailsFail(new Error(`HTTP error! Status: ${response.status}`));
      }
      const result: TableData = await response.json();

      Object.keys(propertyMappings).forEach((property: string) => {
        result[property] = propertyMappings[property][result[property] as number - 1];
      });
      const updatedResult: TableData = {
        ...result,
        source: result.source === 'GNU' ? source[0] : source[1],
        // diamond_price: result.diamond_price !== null ? `${result.diamond_price}` : '-'
      }
      setDiamondDetailsSuccess(updatedResult);
      setIsShowFooter(true);
      if (updatedResult) {
        const {shape, source, carat} = updatedResult;
        const details = {shape: shape as string, type: source as string};
        selectedData(details, carat as string);
      }
    } catch (e) {
      setDiamondDetailsFail(e);
    }
  }, [diamondId, setDiamondDetailsSuccess, setDiamondDetailsLoading, setDiamondDetailsFail, selectedData, fetch]);

  const [excludeAction, setExcludeAction] = useState(false);
  useEffect(() => {
    if (excludeAction && Object.keys(ring.options).length > 0) {
      const {
        Center_Diamond_Size, Diamond_Shape, Ring_Crown, Ring_Side, Ring_Style, Wedding_Band
      } = ring.options;
      if(optionsKey && optionsKey !== "Ring_Side"){
        sendMessage('LoadHead', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${Ring_Crown}` : Ring_Crown}/${Diamond_Shape}/${Center_Diamond_Size}${styleId.includes('RP') ? `/${Ring_Side}` : ''}/head`);
        setIframeLoadActionCount(count => count + 1);
      }
      sendMessage('LoadShank', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${Ring_Crown}` : Ring_Crown}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/shank`);
      setIframeLoadActionCount(count => count + 1);
      if (Wedding_Band !== 'No Band' && ring.extraData.Bands !== 0) {
        sendMessage('LoadBand', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${Ring_Crown}` : Ring_Crown}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/bands`);
        setIframeLoadActionCount(count => count + 1);
      }
      bands3DModel();
      setExcludeAction(false);
    }
  }, [excludeAction, ring.options])
  const handleInitialModel = useCallback((options: { [key: string]: string }, isLoadBands: boolean) => {
    handle3DObjectView('LoadHead', `${styleId}/${styleId?.includes('RB') ? `${options.Ring_Style}${options.Ring_Crown}` : options.Ring_Crown}/${options.Diamond_Shape}/${options.Center_Diamond_Size}${styleId.includes('RP') ? `/${options.Ring_Side}` : ''}/head`);
    handle3DObjectView('LoadShank', `${styleId}/${styleId?.includes('RB') ? `${options.Ring_Style}${options.Ring_Crown}` : options.Ring_Crown}/${options.Diamond_Shape}/${options.Center_Diamond_Size}/${options.Ring_Side}/shank`);
    if (isLoadBands) {
      handle3DObjectView('LoadBand', `${styleId}/${styleId?.includes('RB') ? `${options.Ring_Style}${options.Ring_Crown}` : options.Ring_Crown}/${options.Diamond_Shape}/${options.Center_Diamond_Size}/${options.Ring_Side}/bands`);
      setIframeLoadActionCount(count => count + 1);
    }
    handle3DObjectView('ChangeRingColor',options.ChangeRingColor)
    setIframeLoadActionCount(count => count + 2);
  }, [ring.options, styleId, setIframeLoadActionCount, handle3DObjectView])

  const excludedOptions = useCallback((
    isNotShowOptions: string[],
    selectedOptions: { [key: string]: string },
    allOptionsData: any,
    initialLoad = false,
    isLoadBands: boolean,
    exclusionMatrix: { [key: string]: string }[],
    selectedDiamondOptions:{[key:string]:string} = {}) => {
    setExclusionOption(isNotShowOptions);
    if (isNotShowOptions.length <= 0) {
      if (initialLoad) {
        handleInitialModel(selectedOptions, isLoadBands);
      } else {
        setExcludeAction(true);
      }
      return;
    }
      if (initialLoad) {
        return handleInitialModel(selectedOptions, isLoadBands);
      }
      setExcludeAction(true);
  }, [setExclusionOption, ring.options, setStack, ring.optionsData, handlePDPOptions, setExcludeAction]);
  const [exclusionMatrixOption, setExclusionMatrixOption] = useState<{[key: string]: string[]}>(initialExclusionKey(styleId.includes('RP')));
  const [optionsKey,setOptionsKey] = useState('');
  const handleWeddingBand = (number: number,label: string) => {
    setShowError(false);
    isEditableRingOptions.current = false;
    setBandTabIndex(number);
    setRedirectEditPage(false);
    setRingOptions("Wedding_Band",label);
    ["Single_First_Band", "Double_First_Band", "Double_Second_Band"]
      .filter((band, index) => number === 0 || (number === 1 && index > 0) || (number === 2 && index < 1))
      .forEach(band => setRemoveRingOption(band));
    ["Single_First_Metal_Band", "Double_First_Metal_Band", "Double_Second_Metal_Band"]
      .filter((band, index) => number === 0 || (number === 1 && index > 0) || (number === 2 && index < 1))
      .forEach(band => setRemoveRingOption(band));
    ["First_Band_Engraving", "Second_Band_Engraving"]
      .filter((band, index) => number === 0 || (number === 1 && index > 0) || (number === 2 && index > 1))
      .forEach(band => setRemoveRingOption(band));
    Array.from(Array(number)).map((tabIndex,index)=>{
      setRingOptions(`${label}_${index + 1 === 1 ? 'First' : 'Second'}_Band`,ring.options["Color"]);
      setRingOptions(`${label}_${index + 1 === 1 ? 'First' : 'Second'}_Metal_Band`,`${ring.options["Metal"]}_${ring.options["Color"]}`);
    })
  }
  const handleAllOptions = useCallback(async (key: string, value: any) => {
    const {
      Wedding_Band,
      Single_First_Metal_Band,
      Double_First_Metal_Band,
      Double_Second_Metal_Band
    } = ring.options;
    setShowError(false);
    if(['Metal_Color'].includes(key)){
      const MetalColor={
        "Metal" : value.Code?.split('_')[0],
        "Color" : value.Code?.split('_')[1],
      }
      Object.entries(MetalColor).forEach(([key,value])=> handlePDPOptions(key,value))
    } else {
      handlePDPOptions(key, value.Code);
    }
    if (!["Metal_Color","Diamond_Type","Ring_Size"].includes(key)) {
      const extraData = await getNoBands(ring.extraData, setRingExtraData, key === "Ring_Style" ? value.Code : ring.options.Ring_Style, (key === "Ring_Crown") ? value.Code : ring.options.Ring_Crown, (key === "Ring_Side") ? value.Code : ring.options.Ring_Side,(key === "Diamond_Shape") ? value.Code : ring.options.Diamond_Shape);
      if(extraData?.Bands === 0){
        handleWeddingBand(0, 'No Band');
      }
      setOptionsKey(key);
      const ringExclusionMatrix = getExclusionOptions(exclusionMatrix ,key,value.Code,exclusionMatrixOption,ring.options)
      setExclusionMatrixOption({...ringExclusionMatrix, [key]: exclusionMatrixOption[key]});
      excludedOptions(
        [...Object.values(ringExclusionMatrix).flat(), ...exclusionMatrixOption[key]],
        { ...ring.options, [key]: value.Code },
        ring.optionsData,
        false,
        ring.extraData.Bands !== 0,
        exclusionMatrix
      );
    } else {
      switch (key) {
        case 'Metal_Color':
          const [metal , color] = value.Code?.split('_');
          if(Wedding_Band !== "No Band"){
            const [firstBandMetal,firstBandColor] = (Single_First_Metal_Band ||  Double_First_Metal_Band)?.split('_');
            if(metal !== firstBandMetal){
              sendMessage('ChangeLeftBandColor', firstBandColor);
              handlePDPOptions(`${Wedding_Band}_First_Metal_Band`, `${!["WG"].includes(firstBandColor) && metal ==='PT' ? '18' : metal}_${firstBandColor}`);
              handlePDPOptions(`${Wedding_Band}_First_Band`, `${firstBandColor}`);
            }
            if(Wedding_Band === "Double"){
              const [secondBandMetal,secondBandColor] = Double_Second_Metal_Band?.split('_');
              if(metal !== secondBandMetal){
                sendMessage('ChangeRightBandColor', secondBandColor);
                handlePDPOptions(`${Wedding_Band}_Second_Metal_Band`, `${!["WG"].includes(secondBandColor) && metal ==='PT' ? '18' : metal}_${secondBandColor}`);
                handlePDPOptions(`${Wedding_Band}_Second_Band`, `${secondBandColor}`);
              }
            }
          }
          sendMessage('ChangeRingColor', color);
          break;
        default:
          break;
      }
    }
  }, [getExclusionOptions,getNoBands,setOptionsKey,handlePDPOptions,ring.options,ring.optionsData,setShowError,ring.extraData,sendMessage,setExclusionMatrixOption,excludedOptions]);
  const bands3DModel = useCallback(() => {
    const {Color, Wedding_Band, Single_First_Metal_Band, Double_First_Metal_Band, Double_Second_Metal_Band} = ring.options;
    const pendingValues: { [key: string]: boolean | string } = {};
    if (Color) pendingValues['ChangeRingColor'] = Color;
    if (Wedding_Band !== 'No Band') {
      if (Wedding_Band === "Single" || Wedding_Band === "Double") {
        pendingValues['ChangeLeftBandColor'] = Wedding_Band === "Single" ? Single_First_Metal_Band?.split('_')[1] : Double_First_Metal_Band?.split('_')[1];
        pendingValues['LeftBand'] = true;
        pendingValues['RightBand'] = false;
        if (Wedding_Band === "Double") {
          pendingValues['ChangeRightBandColor'] = Double_Second_Metal_Band?.split('_')[1];
          pendingValues['RightBand'] = true;
        }
      }
    } else {
      ['LeftBand', 'RightBand'].forEach(band => pendingValues[band] = false);
    }
    const pendingRingSideEvents: {
      [key: string]: boolean | string
    }[] = Object.entries(pendingValues).map(([key, value]) => {
      return {action: key, value}
    })
    setPendingEvents(pendingRingSideEvents)
    return true ;
  }, [ring.options, setPendingEvents])

  function sendMessage(action: string, value: string | boolean) {
    window.postMessage({action, value});
  }

  const isEditableRingOptions = useRef(false);
  useEffect(() => {
    //edit code
    if (Object.keys(ring.options).length > 0) {
      isEditableRingOptions.current = true;
      const {
        Ring_Style,
        Ring_Crown,
        Diamond_Shape,
        Center_Diamond_Size,
        Ring_Side,
        Wedding_Band,
        Single_First_Metal_Band,
        Double_First_Metal_Band,
        Double_Second_Metal_Band,
        First_Band_Engraving,
        Second_Band_Engraving,
        Engraving,
        Color
      } = ring.options;

      const styleIdPrefix = styleId && styleId.includes('RB') ? `${Ring_Style}${Ring_Crown}` : Ring_Crown;
      const bandTabIndex = Wedding_Band === 'Single' ? 1 : Wedding_Band === 'Double' ? 2 : 0;
      const bandColor = Wedding_Band === 'Single' ? Single_First_Metal_Band : Wedding_Band === 'Double' ? Double_First_Metal_Band : null;

      const pendingValues: { [key: string]: boolean | string } = {
        'LoadHead': `${styleId}/${styleIdPrefix}/${Diamond_Shape}/${Center_Diamond_Size}${styleId.includes('RP') ? `/${Ring_Side}` : ''}/head`,
        'LoadShank': `${styleId}/${styleIdPrefix}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/shank`,
        'ChangeRingColor': Color
      };
      if (ring.extraData.Bands !== 0) {
        pendingValues['LoadBand'] = `${styleId}/${styleIdPrefix}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/bands`;
        setIframeLoadActionCount(count => count + 1);
      }
      if (Wedding_Band !== 'No Band') {
        if (Wedding_Band === "Single" || Wedding_Band === "Double") {
          setBandTabIndex(bandTabIndex);
          pendingValues['ChangeLeftBandColor'] = bandColor?.split('_')[1] as string;
          pendingValues['LeftBand'] = true;
          pendingValues['RightBand'] = false;
          if (Wedding_Band === "Double") {
            setBandTabIndex(bandTabIndex);
            pendingValues['ChangeRightBandColor'] = Double_Second_Metal_Band?.split('_')[1];
            pendingValues['RightBand'] = true;
          }
        }
      }

      if (Engraving) pendingValues['ShankEngraving'] = Engraving;
      if (First_Band_Engraving) pendingValues['LeftEngraving'] = First_Band_Engraving;
      if (Second_Band_Engraving) pendingValues['RightEngraving'] = Second_Band_Engraving;

      setIframeLoadActionCount(count => count + 2);
      const pendingRingSideEvents = Object.entries(pendingValues).map(([action, value]) => ({action, value}));
      setPendingEvents(pendingRingSideEvents);
      if (!(rpid && uuid) && builder_mode !== "edit") {
        return;
      }
    }

    if (Object.keys(diamond.details).length > 0) {
      const {shape, source, carat} = diamond.details;
      const details = {shape: shape as string, type: source as string};
      selectedData(details, carat as string);
    } else if (diamondId) {
      DetailsData(diamondId);
    } else {
      optionsData({}, null);
    }
  }, []);

  useEffect(() => {
    if (!hasEffectRun.current) {
      TooltipDataFetch(setTooltipDataLoading, SetTooltipDataSuccess, SetTooltipDataError);
    }
    return () => {
      hasEffectRun.current = true;
    }
  }, [])

  useLayoutEffect(() => {
    const {Center_Diamond_Size, Diamond_Shape, Ring_Crown, Ring_Side, Ring_Style, Wedding_Band} = ring.options || {};
    const messages = ["LeftBand", "RightBand"];
    if (Wedding_Band && !isEditableRingOptions.current) {
      if (Wedding_Band !== 'No Band' && !footer.isRedirectToEdit && ring.extraData.Bands !== 0) {
          bands3DModel();
          setIframeLoadActionCount(count => count + 1);
          sendMessage('LoadBand', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${Ring_Crown}` : Ring_Crown}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/bands`);
      } else {
        messages.forEach(message => window.postMessage({action: message, value: false}));
      }
    }
  }, [ring.options["Wedding_Band"]]);

  const validate = useRef(false);

  useEffect(() => {
    if (fieldValidation.length > 0) {
      handleCheckValidation();
    }
  }, [ring.options])

  const handleCheckValidation = useCallback(() => {
    const fieldObject = Object.keys(ring.optionsData);
    setFieldValidation([]);
    fieldObject.forEach((field: string) => {
      const checkData = !Object.keys(ring.options).includes(["Metal_Color"].includes(field) ? "Metal" : field);
      if (checkData) {
        validate.current = true;
        setFieldValidation((prev) => {
          return [...prev, field];
        })
      }
    })
  }, [setFieldValidation, ring.optionsData, ring.options])
  useEffect(() => {
    if (showError) {
      document.querySelector(`button[name=${fieldValidation[0]}]`)?.scrollIntoView({
        behavior: 'smooth', block: 'center', inline: 'start',
      })
    }
  }, [fieldValidation])
  const handleSelectSetting = useCallback(() => {
    setShowError(true);
    handleCheckValidation();
    if (validate.current) {
      validate.current = false;
      return;
    }
    /* window.postMessage({action: "DownloadSnapshot"});
     set3DImageLoading();*/
    setIsShowFooter(true);
    if (screenManager && (screenManager as any).changeScreen) {
      (screenManager as any).props.setStateManagerData('selectedSetting', ring.options,);

      if (Object.keys(diamond.details).length > 0) {
        (screenManager as any).props.setStateManagerData('cost', (diamond.details.b2c_price as number) + +ringPrice,);

        // await storeDesignData();

        return setFooterToggle();
      }
      if (ring.options) {
        const {
          Diamond_Type,
          Diamond_Shape,
          Center_Diamond_Size,
        } = ring.options;
        const findNearestValue = styleId.includes('RP') ? startWithSettingValue : buildYourOwnValue;
        const selectedDiamondOptions = {
          source: Diamond_Type,
          shape: Diamond_Shape,
          carat: `${
            (Number(findNearestValue[Center_Diamond_Size]) -
            Number(findNearestValue[Center_Diamond_Size]) * 0.1).toFixed(2)
          }-${
            (Number(findNearestValue[Center_Diamond_Size]) +
            Number(findNearestValue[Center_Diamond_Size]) * 0.1).toFixed(2)
            // Number(findNearestValue[Center_Diamond_Size]) * (findNearestValue[Center_Diamond_Size] === 3 ? 1 : 0.1)
          }`,
        };
        setAfterRingOptions(selectedDiamondOptions);
      }
      setSettingTabIconTrue();
      // navigate('/select-diamond');
      (screenManager as any).changeScreen({
        viewName: 'diamond',
        id: null,
      });
    }
    /*if(Object.keys(diamond.details).length > 0){
            return setFooterToggle();
        }
        if(ring.options){
            const {Diamond_Type,Diamond_Shape,Center_Diamond_Size} = ring.options;
            const selectedDiamondOptions = {
                source: Diamond_Type,
                shape: [Diamond_Shape],
                carat: `${Number(centerDiamondSizeValue[Center_Diamond_Size]) - Number(centerDiamondSizeValue[Center_Diamond_Size])*0.1}-${Number(centerDiamondSizeValue[Center_Diamond_Size]) + Number(centerDiamondSizeValue[Center_Diamond_Size])*0.1}`
            };
            setAfterRingOptions(selectedDiamondOptions)
        }
        setSettingTabIconTrue();
        navigate('/select-diamond');
    }}*/
  }, [screenManager, setSettingTabIconTrue, setAfterRingOptions, setRingOptions, ring.options, setIsShowFooter]);
  // temp code
  const actionButtonRef = useRef<any>();
  useEffect(() => {
    if (actionButtonRef.current) {
      (actionButtonRef.current as any).addEventListener('3DEvent', (e: any) => {
        console.log('react caught custom event directly on component', e.detail);
        window.postMessage(e.detail);
      });
    }
  }, [actionButtonRef.current]);

 /* useEffect(() => {
    if (["Jenny Packham", "Reve"].includes(ring.extraData.Label)) {
      setRingOptions('Diamond_Type', 'LGN');
      setRingOptions('Metal', '18');
    } else if (["Lotus"].includes(ring.extraData.Label)) {
      setRingOptions('Diamond_Type', 'GNU');
    }
  }, [ring.extraData.Label,ring.options["Diamond_Type"]])*/
  return {
    rpid,
    uuid,
    builder_mode,
    handlePDPOptions,
    handle3DObjectView,
    navigate,
    productName,
    handleAllOptions,
    styleId,
    handleSelectSetting,
    fieldValidation,
    isEditableRingOptions,
    weddingBand,
    ringPrice,
    handleWeddingBand,
    setShowError
  };
};
export default usePdpBuilder;
