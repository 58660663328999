import highlightWords from "highlight-words";
import {MRT_Cell, MRT_RowData, MRT_TableInstance} from "../../types";
import {flexRender} from "@tanstack/react-table";
import {useEffect, useRef, useState} from "react";
import * as React from "react";
import {CheckMark} from "../../../../../Images";
import {ToLocalStringConvert} from "../../../../../../Utils/toLocalStringConvert";

const allowedTypes = ["string", "number", "object"];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
    cell: MRT_Cell<TData>;
    table: MRT_TableInstance<TData>;
}

export const MRT_TableBodyCellValue = <TData extends MRT_RowData>({
                                                                      cell,
                                                                      table,
                                                                  }: Props<TData>) => {
    const {
        getState,
        options: {enableFilterMatchHighlighting, enableClickableCell},
    } = table;
    const {column, row} = cell;
    const {columnDef} = column;
    const {globalFilter, globalFilterFn, getCellData,removeCompareData,compareDataSuccess,compareDataIds} = getState();
    const filterValue = column.getFilterValue();

    let renderedCellValue: any =
        cell.getIsAggregated() && columnDef.AggregatedCell
            ? columnDef.AggregatedCell({
                cell,
                column,
                row,
                table,
            })
            : row.getIsGrouped() && !cell.getIsGrouped()
            ? null
            : cell.getIsGrouped() && columnDef.GroupedCell
                ? columnDef.GroupedCell({
                    cell,
                    column,
                    row,
                    table,
                })
                : undefined;

    const isGroupedValue = renderedCellValue !== undefined;

    if (!isGroupedValue) {
        //@ts-ignore
        renderedCellValue = cell.renderValue();
    }

    if (
        enableFilterMatchHighlighting &&
        columnDef.enableFilterMatchHighlighting !== false &&
        renderedCellValue &&
        allowedTypes.includes(typeof renderedCellValue) &&
        ((filterValue &&
            allowedTypes.includes(typeof filterValue) &&
            columnDef.filterVariant === "text") ||
            (globalFilter &&
                allowedTypes.includes(typeof globalFilter) &&
                column.getCanGlobalFilter()))
    ) {
        const chunks = highlightWords?.({
            text: renderedCellValue?.toString(),
            query: (filterValue ?? globalFilter ?? "").toString(),
            matchExactly:
                (filterValue ? columnDef._filterFn : globalFilterFn) !== "fuzzy",
        });
        if (chunks?.length > 1 || chunks?.[0]?.match) {
            renderedCellValue = (
                <span aria-label={renderedCellValue.toString()} role="note">
          {chunks?.map(({key, match, text}) => (
              <>
              <span
                  aria-hidden="true"
                  key={key}
                  className={classNames(
                      match
                          ? "headless_cell_value_span"
                          : ""
                  )}
              >
                {text}
              </span>
              </>
          )) ?? renderedCellValue}
        </span>
            );
        }
    }

    if (columnDef.Cell && !isGroupedValue) {
        renderedCellValue = columnDef.Cell({
            cell,
            renderedCellValue,
            column,
            row,
            table,
        });
    }
    const ref = useRef<HTMLDivElement>(null);
    const [isShowInputField, setIsShowInputField] = useState<boolean>(false);

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsShowInputField(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    const handleChangeCompare =(row:any)=>{
        if(compareDataIds.includes(row.id)){
            removeCompareData(row);
        }else{
            compareDataSuccess(row);
        }
    }
    return (
        <div
            className={`cursor-pointer ${cell.column.id === "img" && "shape_image_wrap"}`}
            onDoubleClick={() => {
                if (!row.getIsGrouped()) {
                    // setIsShowInputField(true);
                }
            }}
            onClick={() => {
                if (enableClickableCell && !row.getIsGrouped()) {
                    getCellData(renderedCellValue, row.original);
                }
            }}
            ref={ref}>
            {isShowInputField ? (
                <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
            ) : renderedCellValue === row.original.img ?
                (
                    <>
                        {typeof row.original.img === "object" && (
                            <div className={'headless_mobile_onlytitle_shape'}>{row.original.img.shape}</div>
                        )}

                        <div className={`headless_img`}>
                            <img
                                className={`headless_img_item`}
                                alt={row.original.shape}
                                src={
                                    typeof row.original.img === "object"
                                        ? row.original.img.img
                                        : row.original.img
                                }
                            />
                        </div>
                    </>
                ) :  cell.column.id === 'compare' 
                    ? <>
                        <label htmlFor={`compare-${row.id}`} aria-label={`Add to Compare of ${row.original.source} Diamond ${row.original.shape} ${row.original.carat}`}></label>
                        <input type={"checkbox"}
                               id={`compare-${row.id}`}
                               onChange={() => handleChangeCompare(row.original)}
                               checked={compareDataIds.includes(row.original.id) || false}
                               readOnly={true}
                        />
                    </>
                    : cell.column.id === "b2c_price" ?
                        (renderedCellValue)
                            ? `$${ToLocalStringConvert(renderedCellValue as number)}` : '-'
                        : cell.column.id === "scs" ? (
                            <div className={`headless_img scs_image`}>
                            {row.original["scs"] ? <img
                                className={`headless_img_item`}
                                src={CheckMark}
                                alt={`SCS_${row.original["scs"]}_${row.id}`}
                            /> : null}
                        </div>
                    ) : typeof renderedCellValue !== "object" 
                        ? (cell.column.id === "price" 
                            ?`$${(renderedCellValue).toLocaleString('en-IN')}`
                      :renderedCellValue
                    ) : (
                        renderedCellValue !== null && (
                            <div className={"mobile_table_data"}>
                                {Object.entries(renderedCellValue).map(([key, value]) => {
                                    if (value !== "-") {
                                        return (
                                            <div className={"mobile_table_data_items"} key={key}>
                                                <div className="mobile_table_data_label">{key}</div>
                                                <div className="mobile_table_data_span">{value as string}</div>
                                            </div>
                                        );
                                    }
                                })}
                                <div className={'mobile_table_data_items comparison'}>
                                    <label className={'mobile_table_data_label'} htmlFor={`check_${row.original.id}`}>Compare</label>
                                    <input className={'mobile_table_data_span'} type={"checkbox"}
                                           onChange={(event)=> handleChangeCompare(row.original)}
                                           checked={compareDataIds.includes(row.original.id) || false}
                                           id={`check_${row.original.id}`}
                                           readOnly={true}
                                    />
                                </div>
                            </div>
                        )
                    )}
        </div>
    );
}