import React, {useCallback, useEffect, useState} from "react";
import {MultiRangeSliderProps} from "../../types";

const useInputSlider = (props: MultiRangeSliderProps) => {
    const {
        min,
        max,
        selectedValue,
        handleFilterType,
        options,
        removeFilterKey,
        isReset,
        rangeValue,
        setBackToSelectDiamondPage,
        setRemovedMinMaxOptions,
    } = props;
    const [value, setValue] = useState<[number | string, number | string]>( (selectedValue && selectedValue.length > 0 ) ? selectedValue:[min, max]);
    const [isLeftFocus, setIsLeftFocus] = useState<boolean>(false);
    const [isRightFocus, setIsRightFocus] = useState<boolean>(false);
    const [isChangeComplete,setIsChangeComplete] = useState(false);
    const [minMaxValue,setMinMaxValue] = useState<[number, number]>([min, max]);
    const [isInputValue,setIsInputValue] = useState(false);

    const handleChange = useCallback((val: number | number[]): void => {
        if (Array.isArray(val)) {
            setValue(val as [number, number]);
        }
    }, [setValue]);

    useEffect(()=>{
        if(!isInputValue){
            if(isChangeComplete && Object.keys(options).length > 0){
                setValue([value[0],value[1]])
            } else if(selectedValue && selectedValue.length > 0) {
                // setMinMaxValue([min,max])
                setValue( [rangeValue?.[0] || selectedValue[0],rangeValue?.[1] || selectedValue[1]] as [number,number])
            }else{
                setValue( [rangeValue?.[0] || min, rangeValue?.[1] ||max])
            }
            setIsInputValue(false);
        }
    },[min,max,options,selectedValue])

    useEffect(()=>{
        if(isChangeComplete){
            // console.log(min,max,value[0],value[1])
             setMinMaxValue([min,max])
        }
    },[isChangeComplete,min,max])

    const handleLeftInputRange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedNumbers: [number | string, number | string] = [...value];
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/^\$0*/, '');
        let numericValue = inputValue.replace(/[^\d.]/g, '');
        const parts = numericValue.split('.');
        if (parts.length > 1 && parts[1].length > 2) {
            numericValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }
        const minNumber = isNaN(Number(numericValue)) ? '' : numericValue;
        setValue([minNumber,updatedNumbers[1]]);
    }, [setValue, handleFilterType, value]);

    const handleChangeComplete = useCallback((val: number | number[], key: string) => {
        setIsChangeComplete(true);
        if (Array.isArray(val) && (!(value[0] === val[0] && value[1] === val[1]) || (document.querySelector('body.tabbing')))) {
            handleFilterType(`${val[0]}-${val[1]}`, key);
        }
        if(Array.isArray(val) && (val[0] === min) && (val[1] === max)){
            removeFilterKey(key);
            const data = {
                [key]:true
            };
            setRemovedMinMaxOptions && setRemovedMinMaxOptions(data as { [key: string]: boolean });
            setIsChangeComplete(false);
        }
        if(setBackToSelectDiamondPage){
            setBackToSelectDiamondPage(false);
        }
        }, [handleFilterType,setIsChangeComplete,setRemovedMinMaxOptions,removeFilterKey,value]);

    const handleRightInputRange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedNumbers: [number | string, number | string] = [...value];
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/^\$0*/, '');
        let numericValue = inputValue.replace(/[^\d.]/g, '');
        const parts = numericValue.split('.');
        if (parts.length > 1 && parts[1].length > 2) {
            numericValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }
        const maxNumber = isNaN(Number(numericValue)) ? '' : numericValue;
        setValue([updatedNumbers[0],maxNumber]);
    }, [setValue, handleFilterType, value]);

    const handleLeftBlur = (key: string) => {
        setIsInputValue(true);
        const updatedNumbers: [number | string, number | string] = [...value];
        if (+value[0] < min) {
            updatedNumbers[0] = min;
        } else if (+value[0] > max) {
            updatedNumbers[0] = max;
        } else {
            updatedNumbers[0] = +value[0];
        }
        if (updatedNumbers[0] !== value[0]) {
            handleFilterType(`${updatedNumbers[0]}-${updatedNumbers[1]}`, key);
        }
        if((updatedNumbers[0] === min) && (updatedNumbers[1] === max)){
            removeFilterKey(key);
            const data = {
                [key]:true
            };
            setRemovedMinMaxOptions && setRemovedMinMaxOptions(data as { [key: string]: boolean });
        }
        setValue(updatedNumbers);
    };
    const handleLeftBlurWithShowDollar = (key : string) => {
        handleLeftBlur(key)
        setIsLeftFocus(false);
    };
    // reset all filter value when click reset button
    useEffect(() => {
        if (isReset) {
            setValue([rangeValue?.[0] || min, rangeValue?.[1] ||max]);
        }
    }, [isReset])

    const handleLeftFocus = () => {
        setIsLeftFocus(true);
    };
    const handleRightBlurWithShowDollar = (key:string) => {
        handleRightBlur(key);
        setIsRightFocus(false);
    };

    const handleRightBlur = (key: string) => {
        setIsInputValue(true);
        const updatedNumbers: [number | string, number | string] = [...value];
        if (+value[1] > max) {
            updatedNumbers[1] = max;
        } else if (+value[1] < min) {
            updatedNumbers[1] = min;
        } else {
            updatedNumbers[1] = +value[1];
        }
        if (updatedNumbers[1] !== value[1]) {
            handleFilterType(`${updatedNumbers[0]}-${updatedNumbers[1]}`, key);
        }
        if((updatedNumbers[0] === min) && (updatedNumbers[1] === max)){
            removeFilterKey(key);
            const data = {
                [key]:true
            };
            setRemovedMinMaxOptions && setRemovedMinMaxOptions(data as { [key: string]: boolean });
        }
        setValue(updatedNumbers);
    };
    const handleRightFocus = () => {
        setIsRightFocus(true);
    };
    const minValue = isChangeComplete ? minMaxValue[0] :min;
    const maxValue = isChangeComplete ? minMaxValue[1] :max;

    return {
        value,
        handleChange,
        isLeftFocus,
        isRightFocus,
        handleLeftInputRange,
        handleRightInputRange,
        handleChangeComplete,
        handleLeftFocus,
        handleLeftBlurWithShowDollar,
        handleRightBlurWithShowDollar,
        handleLeftBlur,
        handleRightBlur,
        handleRightFocus,
        minValue,
        maxValue
    };
};
export default useInputSlider;
