import React, {FC} from 'react';
import { MaterialReactTable } from 'tanstack-tailwind-table';
import ExpandRowDetails from './ExpandRowDetails';
import { PropsFromScreenManager, TableData } from '../../types';
import { RootState } from '../../reducers/RootReducer';
import {setExpandedRow, compareDataSuccess, removeCompareData, toggleScrollTop,setPagination} from '../../action/TableAction';
import {setBackToSelectDiamondPage} from '../../action/DiamondAction';
import { connect, ConnectedProps } from 'react-redux';
import UseTable from "./useTable";
import {MRT_SortingState} from "../Tanstack-Table/lib/src/types";

const mapStateToProps = (state: RootState) => ({
  table: state.table,
  filter:state.filter
});

const mapDispatchToProps = {
  setExpandedRow,
  setPagination,
  removeCompareData,
  compareDataSuccess,
  toggleScrollTop,
  setBackToSelectDiamondPage
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromTableRedux = ConnectedProps<typeof connector>;

export interface TableType extends PropsFromTableRedux {
    data: TableData[],
    isLoading: boolean,
    error: unknown | string
    isFetching?: boolean,
    rowCount?: number,
    enablePagination?:boolean,
    manualSorting?:boolean,
    sorting?: MRT_SortingState,
    setSorting?: any,
    isGrouping?: boolean;
}

const Table: FC<TableType & PropsFromScreenManager> = (props) => {
  const {
    data,
    isLoading,
    error,
    setExpandedRow,
    table,
    instanceData,
    filter,
    setPagination,
    isFetching = false,
    rowCount= 0,
    enablePagination= false,
    manualSorting= false,
    sorting = [],
    setSorting = () => {},
    compareDataSuccess,
    removeCompareData,
    toggleScrollTop,
    setBackToSelectDiamondPage,
    isGrouping = false
  } = props;
  const { isMobileView, tableData, columns,compareDataIds, scrollTop,setScrollTop } = UseTable({ data, table,filter,isGrouping });
  if (error) {
    return <div className={'p-5 mx-auto'}>Error...</div>;
  }
  return (
    <div className={"mx-auto max-w-site"}>
      <MaterialReactTable
        //@ts-ignore
        columns={columns}
        data={tableData}
        enableTopToolbar={false}
        enableExpandAll={false}
        enableSorting={true}
        enableColumnActions={false}
        enablePagination={enablePagination}
        enableGrouping={isGrouping}
        manualPagination={enablePagination}
        manualSorting={manualSorting}
        rowCount={rowCount}
        enableTableHead={!isMobileView}
        initialState={{
          compareDataSuccess,
          ...isGrouping && {expanded: true},
          removeCompareData,
          setExpandedRow,
          toggleScrollTop,
          setPagination,
          setBackToSelectDiamondPage,
          setScrollTop,
          scrollTop:scrollTop,
          reduxPagination : table.pagination,
          activeTab: table.activeTab,
          isMobileView: isMobileView,
          isLoading:(isLoading || isFetching),
          pagination: { pageIndex: table.pagination[table.activeTab].pageIndex, pageSize: table.pagination[table.activeTab].pageSize },
          expandRow: table.expandRow,
          compareDataIds:compareDataIds,
          instanceData:instanceData,
          scrollToTop:table.scrollToTop,
          ...isGrouping && {grouping: ["type"]},
          paginationArray: +rowCount > table.pagination[table.activeTab].pageSize ? [50, 100, 150, 200] : [],
        }}
        {...manualSorting && { onSortingChange: setSorting}}

        state={{
          showProgressBars: isFetching,
          ...(manualSorting && { sorting: sorting })
        }}
        renderDetailPanel={({row}) => {
          return (
            !isMobileView && (+row.id === +row.index) ?
              <ExpandRowDetails
                row={row.original}
                instanceData={instanceData}
              />
              : ''
          );
        }}
      />
    </div>
  );
};

export default connector(Table);
